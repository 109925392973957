import React from "react";
import logo from "../../assets/logo_o1_sap.png";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";

const Calendar = () => {


  return (
    <>
      <header className="bg-white shadow mt-16">
        <div className="flex container mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="flex-1 text-3xl font-bold leading-tight text-sap-blue tracking-widest uppercase">
            Calendrier
          </h1>
          <img
            className="h-10 flex-3 hidden md:block"
            src={logo}
            alt="compagny_logo"
          />
        </div>
      </header>
      <div className="flex-1 bg-gradient-to-b from-sap-blue via-sap-blue-light to-sap-gray p-5">
        <main className="antialiased font-sans">
          <div className="container mx-auto bg-sap-gray rounded-sm p-5 mt-5">
            <FullCalendar
              plugins={[dayGridPlugin]}
              initialView="dayGridMonth"
              locale="fr"
              height="auto"
            />
          </div>
        </main>
      </div>
    </>
  );
};

export default Calendar;
