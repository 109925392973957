import React, { useState, useEffect, useCallback, useRef } from "react";
import API from "../../utils/API";
import Ticket from "../Ticket";
import Filters from "../Filters";
import MessageOld from "../MessageOld";
import { useHistory } from "react-router-dom";
import "../../utils/animations.css";
import logo from "../../assets/logo_o1_sap.png";

const Dashboard = (props) => {
  const [data, setData] = useState({ ServiceCallsList: [] }),
    [loading, setLoading] = useState(false),
    [rows, setRows] = useState({ fetch: 0, next: 20 }),
    [status, setStatus] = useState({ id: "A", value: "Tous" }),
    [typing, setTyping] = useState(""),
    [priority, setPriority] = useState({ id: "", value: "Toutes" }),
    [importance, setImportance] = useState({ id: "", value: "Toutes" }),
    [client, setClient] = useState({ cardCode: "", name: "Tous" }),
    [search, setSearch] = useState(""),
    [show, setShow] = useState({
      add: false,
      update: false,
    }),
    [toSave, setToSave] = useState(false),
    [isFiltersPanelOpen, setContentWidth] = useState(false),
    [sort, setSort] = useState({ dir: "", order: "" }),
    [message, setMessage] = useState({}),
    history = useHistory(),
    getData = useCallback(() => {
      setLoading(true);
      try {
        API.serviceCalls({
          ...rows,
          status: status.id,
          priority: priority.id,
          importance: importance.id,
          search: search,
          cardCode: client.cardCode,
          sortDir: sort.dir,
          sortOrder: sort.order,
        })
          .then((res) => {
            setData(res.data);
            setLoading(false);
          })
          .catch((err) => {
            (err.response.status >= 400 || err.response.status === 500) &&
              setMessage({
                type: "error",
                title: "Erreur",
                text: err.response.data.text,
              });
            setLoading(false);
            //history.push("/");
            //API.logout();
            //throw err;
          });
      } catch (error) {
        console.error(error);
      }
    }, [history, rows, status, priority, importance, search, client, sort]),
    getColor = (status) => {
      switch (status) {
        case "Clôturé":
          return "gray";
        case "En attente Client":
          return "blue";
        case "Ouvert":
          return "red";
        case "Ré-ouvert":
          return "red";
        case "En attente Editeur":
          return "blue";
        default:
      }
    },
    getPriority = (priority) => {
      switch (priority) {
        case "L":
          return "BASSE";
        case "M":
          return "MOYENNE";
        case "H":
          return "ÉLEVÉE";
        case "BASSE":
          return "L";
        case "MOYENNE":
          return "M";
        case "ÉLEVÉE":
          return "H";
        default:
          return "";
      }
    },
    handleRows = (e) => {
      const { name, value } = e.currentTarget;
      setRows((rows) => ({ ...rows, [name]: Number(value) }));
    },
    handleStatus = (e) => {
      const value = e.target.value;
      switch (value) {
        case "Tous":
          setStatus({ id: "A", value: value });
          break;
        case "Ouverts":
          setStatus({ id: "O", value: value });
          break;
        case "Clôturés":
          setStatus({ id: "C", value: value });
          break;
        default:
      }
    },
    handlePriority = (e) => {
      const value = e.target.value;
      setPriority({ id: getPriority(value.toUpperCase()), value: value });
    },
    handleClient = (e) => {
      setClient({ cardCode: e.cardCode, name: e.cardName });
    },
    handleImportance = (e) => {
      const value = e.target.value;
      switch (value) {
        case "Annulé":
          setImportance({ id: "5", value: value });
          break;
        case "Suspendu":
          setImportance({ id: "4", value: value });
          break;
        case "Non urgent":
          setImportance({ id: "3", value: value });
          break;
        case "Standard":
          setImportance({ id: "2", value: value });
          break;
        case "Prioritaire":
          setImportance({ id: "1", value: value });
          break;
        default:
          setImportance({ id: "", value: value });
      }
    },
    closeTicket = () => {
      setShow({
        add: false,
        update: false,
        ticket: "",
      });
      getData();
    },
    clearFilters = () => {
      setRows({ fetch: 0, next: 20 });
      setStatus({ id: "A", value: "Tous" });
      setPriority({ id: "", value: "Toutes" });
      setImportance({ id: "", value: "Toutes" });
      setClient({ cardCode: "", name: "Tous" });
      setTyping("");
      setSearch("");
      setSort({ dir: "", order: "" });
    },
    callSetToSave = useCallback((state) => {
      setToSave(state);
    }, []),
    fromFiltersPanel = (state) => {
      setContentWidth(state);
    },
    handleOrder = (dir = "", order = "") => {
      setSort({ dir: dir, order: order });
    };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setSearch(typing);
      setRows({ fetch: 0, next: rows.next });
    }, 800);
    return () => clearTimeout(delayDebounceFn);
  }, [typing, rows.next]);
  useEffect(() => {
    getData();
  }, [getData]);
  const triggerSave = useRef();
  return (
    <>
      <MessageOld message={message} cleanMsg={setMessage} />
      <Filters
        isFiltersPanelOpen={isFiltersPanelOpen}
        setDashboardWidth={fromFiltersPanel}
        rows={rows.next}
        handleRows={handleRows}
        status={status.value}
        handleStatus={handleStatus}
        typing={typing}
        setTyping={setTyping}
        priority={priority.value}
        handlePriority={handlePriority}
        importance={importance.value}
        handleImportance={handleImportance}
        client={client.name}
        handleClient={handleClient}
        clearFilters={clearFilters}
      />
      <header className="bg-white mt-16 md:mt-0 md:fixed top-16 w-full z-10 shadow">
        <div className="flex container mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <h1
            className={`flex-1 text-3xl font-bold leading-tight text-sap-blue tracking-widest uppercase transition ease-${
              !isFiltersPanelOpen ? "in" : "out"
            } duration-${!isFiltersPanelOpen ? "500" : "500"} opacity-${
              !isFiltersPanelOpen ? "100" : "0"
            } transform-gpu translate-x-${!isFiltersPanelOpen ? "0" : "1/4"}`}
          >
            Tickets
            <button
              className="ml-3 bg-white focus:outline-none hover:text-white hover:bg-sap-yellow hover:border-sap-yellow text-sap-blue font-semibold py-2 px-2 border border-gray-400 rounded-full shadow transition ease-in-out duration-150"
              onClick={() => setContentWidth(!isFiltersPanelOpen)}
            >
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                />
              </svg>
            </button>
          </h1>
          <h2 className="hidden md:block text-lg font-semibold tracking-widest text-sap-blue uppercase text-right md:mt-2 mr-4">
            {toSave
              ? "Mettre à jour et fermer"
              : show.add || show.update
              ? "Fermer"
              : "Nouvelle demande"}
          </h2>
          <button
            className="z-20 flex-2 mr-3 bg-white focus:outline-none hover:text-white hover:bg-sap-yellow hover:border-sap-yellow text-sap-blue font-semibold py-2 px-2 border border-gray-400 rounded-full shadow transition ease-in-out duration-150"
            onClick={() =>
              toSave
                ? triggerSave.current.updateServiceCall()
                : setShow(
                    show.update
                      ? { ...show, update: false, ticket: "" }
                      : { ...show, add: !show.add, ticket: "" }
                  )
            }
          >
            {toSave ? (
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4"
                />
              </svg>
            ) : show.add || show.update ? (
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                />
              </svg>
            )}
          </button>
          <img
            className="h-10 flex-3 hidden md:block"
            src={logo}
            alt="compagny_logo"
          />
        </div>
      </header>
      <div className="flex-1 md:mt-36 bg-gradient-to-b from-sap-blue via-sap-blue-light to-sap-gray p-3">
        <main className="antialiased font-sans">
          <div
            className={`transition ease-${
              isFiltersPanelOpen ? "in" : "out"
            } duration-${isFiltersPanelOpen ? "500" : "500"} ${
              isFiltersPanelOpen ? "md:w-3/4" : "container"
            } md:m${isFiltersPanelOpen ? "l-16" : "x-auto"} transform-gpu ${
              isFiltersPanelOpen ? "md:translate-x-1/4" : "translate-x-0"
            }`}
          >
            <div className="sm:py-1 md:pt-5 overflow-hidden">
              <Ticket
                show={show.add ? show.add : show.update}
                title={
                  show.add
                    ? "Créer un nouveau ticket de support"
                    : `#${show.ticket}`
                }
                closeTicket={closeTicket}
                ticket={show.ticket}
                getPriority={getPriority}
                getColor={getColor}
                callSetToSave={callSetToSave}
                ref={triggerSave}
                setMessage={setMessage}
              />
              {!show.add && !show.update && (
                <>
                  <div className="OM_table">
                    <div className="inline-block min-w-full rounded-sm overflow-hidden">
                      {!data.ServiceCallsList.length && loading && (
                        <div className="lds-facebook">
                          <div></div>
                          <div></div>
                          <div></div>
                        </div>
                      )}
                      {!data.ServiceCallsList.length && !loading && (
                        <h2 className="absoluteCentered text-lg font-bold leading-tight text-sap-blue">
                          Il n'y a pas de résultat pour cette recherche
                        </h2>
                      )}
                      <table className="list-table">
                        <thead>
                          <tr>
                            <th className="px-2 py-3 pl-10 border-b-2 border-gray-300 bg-sap-gray text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                              <b>#</b>
                            </th>
                            <th className="px-2 py-3 pl-5 border-b-2 border-gray-300 bg-sap-gray text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                              <b>Société</b>
                            </th>
                            <th className="px-2 py-3 border-b-2 border-gray-300 bg-sap-gray text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                              <b>Sujet</b>
                            </th>
                            <th className="px-2 py-3 border-b-2 border-gray-300 bg-sap-gray text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                              <b>Type de problème</b>
                            </th>
                            <th className="px-2 py-3 border-b-2 border-gray-300 bg-sap-gray text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                              <b>Type d'appel</b>
                            </th>
                            <th className="px-2 py-3 border-b-2 border-gray-300 bg-sap-gray text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                              <b>Créé le</b>
                            </th>
                            <th className="px-2 py-3 border-b-2 border-gray-300 bg-sap-gray text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                              <b>Assigné à</b>
                            </th>
                            <th className="px-2 py-3 border-b-2 border-gray-300 bg-sap-gray text-center text-xs font-semibold text-gray-600 uppercase tracking-wider leading-6">
                              <b>Priorité</b>
                              <svg
                                className="h-6 w-6 float-right cursor-pointer"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                onClick={() =>
                                  handleOrder(
                                    sort.dir === "asc" ? "desc" : "asc",
                                    "priority"
                                  )
                                }
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M8 9l4-4 4 4m0 6l-4 4-4-4"
                                />
                              </svg>
                            </th>
                            <th className="px-2 py-3 border-b-2 border-gray-300 bg-sap-gray text-center text-xs font-semibold text-gray-600 uppercase tracking-wider leading-6">
                              <b>Importance</b>
                              <svg
                                className="h-6 w-6 float-right cursor-pointer"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                                onClick={() =>
                                  handleOrder(
                                    sort.dir === "asc" ? "desc" : "asc",
                                    "importance"
                                  )
                                }
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M8 9l4-4 4 4m0 6l-4 4-4-4"
                                />
                              </svg>
                            </th>
                            <th className="px-2 py-3 pr-10 border-b-2 border-gray-300 bg-sap-gray text-right text-xs font-semibold text-gray-600 uppercase tracking-wider">
                              <b>Statut</b>
                            </th>
                          </tr>
                        </thead>
                        <tbody
                          className={`${loading && "shiny"} transition ease-${
                            data.ServiceCallsList.length ? "out" : "in"
                          } duration-${
                            data.ServiceCallsList.length ? "100" : "75"
                          } transform opacity-${
                            data.ServiceCallsList.length ? "100" : "0"
                          } scale-${
                            data.ServiceCallsList.length ? "100" : "95"
                          }`}
                        >
                          {!data.ServiceCallsList.length && (
                            <tr className="h-96"></tr>
                          )}
                          {data.ServiceCallsList.map((e) => (
                            <tr
                              key={data.ServiceCallsList.indexOf(e)}
                              className={`cursor-pointer transition duration-500 ease-in-out hover:py-3 px-6 list-item`}
                              onClick={() =>
                                setShow({
                                  ...show,
                                  update: !show.update,
                                  ticket: e.callID,
                                })
                              }
                            >
                              <td
                                className={`py-4 px-2 pl-8 border-gray-200}`}
                              >
                                <p className="text-gray-800 whitespace-nowrap text-left">
                                  {e.callID}
                                </p>
                              </td>
                              <td
                                className={`py-4 px-2 border-gray-200 text-sm`}
                              >
                                <div className="flex items-center">
                                  <div className="ml-3">
                                    <p className="text-gray-900 whitespace-nowrap overflow-hidden truncate w-32">
                                      {e.custmrName}
                                    </p>
                                  </div>
                                </div>
                              </td>
                              <td
                                className={`py-4 px-2 border-gray-200`}
                              >
                                <p className="text-gray-900 whitespace-nowrap overflow-hidden truncate w-40 text-left">
                                  {e.subject}
                                </p>
                              </td>
                              <td
                                className={`py-4 px-2 border-gray-200 text-sm`}
                              >
                                <p className="text-gray-900 whitespace-nowrap text-center">
                                  {e.problemTyp}
                                </p>
                              </td>
                              <td
                                className={`py-4 px-2 border-gray-200 text-sm`}
                              >
                                <p className="text-gray-900 whitespace-nowrap text-center w-28 truncate">
                                  {e.callType}
                                </p>
                              </td>
                              <td
                                className={`py-4 px-2 border-gray-200 text-center text-sm`}
                              >
                                <p className="text-gray-900 whitespace-nowrap">
                                  {e.createDate
                                    .split(" ")[0]
                                    .split("-")
                                    .reverse()
                                    .join("-")}
                                </p>
                              </td>
                              <td
                                className={`py-4 px-2 border-gray-200 text-center text-sm`}
                              >
                                <p className="text-gray-900 whitespace-nowrap w-32 truncate">
                                  {e.queue ? e.queue : e.assignee}
                                </p>
                              </td>
                              <td
                                className={`py-4 px-2 border-gray-200 text-center text-sm`}
                              >
                                <p className="text-gray-900 whitespace-nowrap">
                                  {getPriority(e.priority)}
                                </p>
                              </td>
                              <td
                                className={`py-4 px-2 border-gray-200 text-center text-sm`}
                              >
                                <p className="text-gray-900 whitespace-nowrap">
                                  {e.nivImporDesc}
                                </p>
                              </td>
                              <td
                                className={`py-4 px-2 pr-8 border-gray-200 text-right text-sm`}
                              >
                                <span
                                  className={`relative inline-block px-3 py-1 font-semibold text-${getColor(
                                    e.status
                                  )}-900 leading-tight`}
                                >
                                  <span
                                    aria-hidden
                                    className={`absolute inset-0 bg-${getColor(
                                      e.status
                                    )}-200 opacity-50 rounded-full`}
                                  ></span>
                                  <span className="relative">
                                    {e.status.split(" ").pop()}
                                  </span>
                                </span>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                      <div className="w-12/12 mx-auto bg-white px-4 py-3 flex items-center border-gray-200 sm:px-6">
                        <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                          <div>
                            <p className="text-sm leading-5 text-gray-700">
                              Résultat :{" "}
                              <span className="font-medium">
                                {rows.fetch + 1}
                              </span>{" "}
                              sur{" "}
                              <span className="font-medium">
                                {rows.fetch + rows.next > data.Total
                                  ? data.Total
                                  : rows.fetch + rows.next}
                              </span>{" "}
                              de{" "}
                              <span className="font-medium">{data.Total}</span>{" "}
                              au total
                            </p>
                          </div>
                          <div>
                            <nav className="relative z-0 inline-flex shadow-sm">
                              {/* First */}
                              <button
                                disabled={!rows.fetch || loading}
                                name="fetch"
                                value={ 0 }
                                onClick={(event) => handleRows(event)}
                                className={`relative inline-flex items-center px-2 py-2 rounded-l-sm disabled:opacity-50 ${
                                  !rows.fetch && "pointer-events-none"
                                } border border-gray-400 bg-white text-sm leading-5 font-medium focus:outline-none hover:text-white hover:bg-sap-yellow hover:border-sap-yellow text-sap-blue transition ease-in-out duration-150`}
                                aria-label="Previous"
                              >
                                Début
                              </button>
                              {/* Previous */}
                              <button
                                disabled={!rows.fetch || loading}
                                name="fetch"
                                value={
                                  rows.fetch - rows.next <= 0
                                    ? 0
                                    : rows.fetch - rows.next
                                }
                                onClick={(event) => handleRows(event)}
                                className={`relative inline-flex items-center px-2 py-2 rounded-l-sm disabled:opacity-50 ${
                                  !rows.fetch && "pointer-events-none"
                                } border border-gray-400 bg-white text-sm leading-5 font-medium focus:outline-none hover:text-white hover:bg-sap-yellow hover:border-sap-yellow text-sap-blue transition ease-in-out duration-150`}
                                aria-label="Previous"
                              >
                                <svg
                                  className="h-5 w-5"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </button>
                              {/* Next */}
                              <button
                                disabled={
                                  data.Total - rows.fetch <= rows.next ||
                                  loading
                                }
                                name="fetch"
                                value={rows.fetch + rows.next}
                                onClick={(event) => handleRows(event)}
                                className={`-ml-px relative inline-flex items-center px-2 py-2 rounded-r-sm disabled:opacity-50 ${
                                  data.Total - rows.fetch <= rows.next &&
                                  "pointer-events-none"
                                } border border-gray-400 bg-white text-sm leading-5 font-medium focus:outline-none hover:text-white hover:bg-sap-yellow hover:border-sap-yellow text-sap-blue transition ease-in-out duration-150`}
                                aria-label="Next"
                              >
                                <svg
                                  className="h-5 w-5"
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              </button>
                              {/* Last */}
                              <button
                                disabled={
                                  data.Total - rows.fetch <= rows.next ||
                                  loading
                                }
                                name="fetch"
                                value={data.Total ? data.Total - rows.next : 0}
                                onClick={(event) => handleRows(event)}
                                className={`-ml-px relative inline-flex items-center px-2 py-2 rounded-r-sm disabled:opacity-50 ${
                                  data.Total - rows.fetch <= rows.next &&
                                  "pointer-events-none"
                                } border border-gray-400 bg-white text-sm leading-5 font-medium focus:outline-none hover:text-white hover:bg-sap-yellow hover:border-sap-yellow text-sap-blue transition ease-in-out duration-150`}
                                aria-label="Next"
                              >
                                Fin
                              </button>
                            </nav>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="xl:hidden">
                    {!data.ServiceCallsList.length && loading && (
                      <div className="xl:hidden lds-facebook mt-36">
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    )}
                  </div>
                  <div
                    className={`xl:hidden grid md:grid-cols-2 lg:grid-cols-3 gap-4 transition ease-${
                      data.ServiceCallsList.length ? "out" : "in"
                    } duration-${
                      data.ServiceCallsList.length ? "100" : "75"
                    } transform opacity-${
                      data.ServiceCallsList.length ? "100" : "0"
                    } scale-${data.ServiceCallsList.length ? "100" : "95"}`}
                  >
                    {data.ServiceCallsList.map((e) => (
                      <div
                        key={data.ServiceCallsList.indexOf(e)}
                        className="p-1"
                        onClick={() =>
                          setShow({
                            ...show,
                            update: !show.update,
                            ticket: e.callID,
                          })
                        }
                      >
                        <div className="border border-gray-300 bg-white p-4 rounded-sm">
                          <span
                            className={`float-right relative inline-block px-3 py-1 font-semibold text-${getColor(
                              e.status
                            )}-900 leading-tight`}
                          >
                            <span
                              aria-hidden
                              className={`absolute inset-0 bg-${getColor(
                                e.status
                              )}-200 opacity-50 rounded-full`}
                            ></span>
                            <span className="relative">{e.status}</span>
                          </span>
                          <h2 className="text-lg font-medium title-font mb-2">
                            {e.callID}
                          </h2>
                          <hr />
                          <div className="mt-2 leading-none flex justify-between w-full">
                            <span className="mr-3 inline-flex items-left leading-none text-sm  py-1 ">
                              {e.custmrName}
                            </span>
                            <span className="inline-flex items-center leading-none text-sm">
                              <svg
                                className="min-w-5 min-h-5 w-4 h-4 mr-1 text-blue-500"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                              </svg>
                              {e.queue ? e.queue : e.assignee}
                            </span>
                          </div>
                          <p className="leading-relaxed text-base pt-2">
                            {e.subject}
                          </p>
                          <hr />
                          <div className="text-center mt-2 leading-none flex justify-between w-full">
                            <span className="mr-3 inline-flex items-center leading-none text-sm  py-1 ">
                              <svg
                                className="min-w-5 min-h-5 w-4 h-4 mr-1 text-blue-500"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={2}
                                  d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                              </svg>{" "}
                              {e.createDate
                                .split(" ")[0]
                                .split("-")
                                .reverse()
                                .join("-")}
                            </span>
                            <span className="inline-flex items-center leading-none text-sm">
                              <svg
                                className="min-w-5 min-h-5 mr-1"
                                width="22"
                                height="22"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g fill="none" fillRule="evenodd">
                                  <path
                                    fill={
                                      e.priority === "H" ? "#1F2937" : "#D8D8D8"
                                    }
                                    d="M15.6 17.667h3.5V3h-3.5z"
                                  />
                                  <path
                                    fill={
                                      e.priority !== "L" ? "#1F2937" : "#D8D8D8"
                                    }
                                    d="M9.2 6.583v11.08h3.5V6.583z"
                                  />
                                  <path
                                    fill="#1F2937"
                                    d="M2.6 17.667h3.5v-7.334H2.6z"
                                  />
                                </g>
                              </svg>
                              {getPriority(e.priority)}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Dashboard;
