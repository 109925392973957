export function message(msg) {
  return (dispatch) => {
    if (msg) {
      dispatch({ type: "MESSAGE", msg: msg });
      setTimeout(() => {
        dispatch({ type: "CLEAR_MESSAGE" });
      }, 3000);
      switch (msg.type) {
        case "success":
          dispatch({ type: "SUCCESS", msg: msg });
          break;
        case "info":
          dispatch({ type: "INFO", msg: msg });
          break;
        case "error":
          dispatch({ type: "ERROR", msg: msg });
          break;
        default:
      }
    } else {
      dispatch({ type: "CLEAR_MESSAGE" });
    }
  };
}
