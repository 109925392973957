import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
} from "react";
import API from "../../utils/API";
import { useHistory } from "react-router-dom";
import ImportanceLevel from "../ImportanceLevel";
import "../../utils/animations.css";

const Ticket = forwardRef((props, ref) => {
  const [data, setData] = useState({}),
    [files, getFiles] = useState([]),
    [fileParams, setFile] = useState([]),
    [loading, setLoading] = useState(true),
    [comment, setComment] = useState({ open: false, text: "" }),
    [enlarge, setEnlarge] = useState(false),
    [inputs, setInputs] = useState({
      subject: "",
      company: "",
      description: "",
      problemType: "",
      callType: "",
      priority: "",
    }),
    [importance, setImportance] = useState({
      state: false,
      level: "",
    }),
    el = useRef(),
    { subject, company, description, problemType, callType, priority } = inputs,
    handleChange = (e) => {
      const { name, value } = e.target;
      setInputs((inputs) => ({ ...inputs, [name]: value }));
    },
    getBinaryFromFile = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.addEventListener("load", () => resolve(reader.result));
        reader.addEventListener("error", (err) => reject(err));
        reader.readAsDataURL(file);
      });
    },
    handleFile = async (e) => {
      const FilesList = e.target.files;
      if (FilesList[0]) {
        for (let i = 0; i < FilesList.length; i++) {
          const file = FilesList[i];
          const binary = await getBinaryFromFile(file);
          setFile((fileParams) => [
            ...fileParams,
            {
              name: file.name.substring(0, file.name.lastIndexOf(".")),
              pltype: file.name.split(".").pop(),
              value: binary.split(",").pop(),
            },
          ]);
        }
      }
    },
    history = useHistory(),
    sendMessage = props.setMessage,
    getData = useCallback(() => {
      try {
        API[props.ticket ? "serviceCalls" : "probCallTypes"]({
          callID: props.ticket,
        })
          .then((res) => {
            if (props.ticket) {
              setData(res.data.ServiceCallsList[0]);
              getFiles(res.data.Files);
            } else {
              setData(res.data);
            }
            setLoading(false);
            setComment((comment) => ({ ...comment, open: false }));
          })
          .catch((err) => {
            (err.response.status >= 400 || err.response.status === 500) &&
              sendMessage({
                type: "error",
                title: "Erreur",
                text: err.response.data.text,
              });
            setLoading(false);
            //history.push("/");
            //API.logout();
            throw err;
          });
      } catch (error) {
        console.error(error);
      }
    }, [history, props.ticket, sendMessage]),
    handleSubmit = async (e) => {
      e.preventDefault();
      setLoading(true);
      if (data.BusinessPartners) {
        const validCompagny = data.BusinessPartners.find((e) => {
          return e.cardName.toLowerCase() === inputs.company.toLowerCase();
        });
        if (!validCompagny) {
          return (
            props.setMessage({
              type: "error",
              title: "Erreur",
              text: "Société invalide",
            }),
            setInputs((inputs) => ({ ...inputs, company: "" })),
            setLoading(false)
          );
        }
        inputs.code = validCompagny.cardCode;
      }
      const validProblemType = data.ProblemTypes.find((e) => {
        return e.name.toLowerCase() === inputs.problemType.toLowerCase();
      });
      if (!validProblemType) {
        return (
          props.setMessage({
            type: "error",
            title: "Erreur",
            text: "Type de problème invalide",
          }),
          setInputs((inputs) => ({ ...inputs, problemType: "" })),
          setLoading(false)
        );
      }
      inputs.problemTypeCode = validProblemType.id;
      const validCallType = data.CallTypes.find((e) => {
        return e.name.toLowerCase() === inputs.callType.toLowerCase();
      });
      if (!validCallType) {
        return (
          props.setMessage({
            type: "error",
            title: "Erreur",
            text: "Type d'appel invalide",
          }),
          setInputs((inputs) => ({ ...inputs, callType: "" })),
          setLoading(false)
        );
      }
      inputs.problemTypeCode = validCallType.id;
      if (!props.getPriority(inputs.priority)) {
        return (
          props.setMessage({
            type: "error",
            title: "Erreur",
            text: "Priorité invalide",
          }),
          setInputs((inputs) => ({ ...inputs, priority: "" })),
          setLoading(false)
        );
      }
      const params = {
        subject: inputs.subject,
        ...(data.BusinessPartners && { code: inputs.code }),
        priority: props.getPriority(inputs.priority),
        problemType: Number(
          data.ProblemTypes.find((e) => e.name === inputs.problemType).id
        ),
        callType: Number(
          data.CallTypes.find((e) => e.name === inputs.callType).id
        ),
        description: inputs.description,
        ...(Object.keys(fileParams).length && { files: fileParams }),
      };
      try {
        API.saveServiceCall(params)
          .then((response) => {
            response.status === 201 &&
              props.setMessage({
                type: "success",
                title: "Succès",
                text: `Appel n°${response.data.message} créé`,
              });
            props.closeTicket();
          })
          .catch((err) => {
            (err.response.status >= 400 || err.response.status === 500) &&
              props.setMessage({
                type: "error",
                title: "Erreur",
                text:
                  err.response.status === 413
                    ? `Pièce${fileParams.length > 1 ? "s" : ""} jointe${
                        fileParams.length > 1 ? "s" : ""
                      } trop volumineuse${fileParams.length > 1 ? "s" : ""}`
                    : err.response.data.text,
              });
            setLoading(false);
            setFile([]);
            throw err;
          });
      } catch (error) {
        console.error(error);
      }
    },
    checkImportanceLevel = useCallback(
      (state, level) => {
        setImportance({ state: state, level: level });
        props.callSetToSave(state);
      },
      [props]
    );

  useImperativeHandle(ref, () => ({
    updateServiceCall() {
      setLoading(true);
      try {
        API.updateServiceCall({
          callID: data.callID,
          nivImporID: importance.level,
        })
          .then((response) => {
            response.status === 200 &&
              props.setMessage({
                type: "success",
                title: "Succès",
                text: "Modification prise en compte",
              });
            setData({});
            checkImportanceLevel(false, "");
            props.closeTicket();
          })
          .catch((err) => {
            (err.response.status >= 400 || err.response.status === 500) &&
              props.setMessage({
                type: "error",
                title: "Erreur",
                text: err.response.data.text,
              });
            setLoading(false);
            throw err;
          });
      } catch (error) {
        console.error(error);
      }
    },
  }));
  useEffect(() => {
    setData({});
    getFiles([]);
    setLoading(true);
    setFile([]);
    setComment({ open: false, text: "" });
    setEnlarge({ resolution: false, descrption: false });
    setInputs({
      subject: "",
      company: "",
      description: "",
      problemType: "",
      callType: "",
      priority: "",
    });
    if (!props.show) {
      setLoading(true);
    } else {
      getData();
    }
  }, [getData, props.show]);
  return (
    <>
      <div
        className={`container md:pt-5 ${
          props.show ? "relative" : "absolute top-0 -z-1"
        } right-0 left-0 mx-auto transition ease-${
          props.show ? "out" : "in"
        } duration-${props.show ? "100" : "75"} transform opacity-${
          props.show ? "100" : "0"
        } scale-${props.show ? "100" : "95"}`}
      >
        {loading && (
          <div className="lds-facebook">
            <div></div>
            <div></div>
            <div></div>
          </div>
        )}
        <div
          className={`shadow${
            loading ? "-2xl" : ""
          } overflow-hidden border-b border-gray-200 sm:rounded-sm mx-auto sm:w-full md:w-4/6`}
        >
          <main
            className={`bg-white shadow mx-auto py-6 px-4 sm:px-6 lg:px-8 transition ease-${
              loading ? "out" : "in"
            } duration-${loading ? "300" : "200"} transform opacity-${
              loading ? "0" : "100"
            }`}
          >
            <span
              className={`float-right mt-1 ml-1 relative inline-block px-3 py-1 font-semibold text-${props.getColor(
                data.status
              )}-900 leading-tight`}
            >
              <span
                aria-hidden
                className={`absolute inset-0 bg-${props.getColor(
                  data.status
                )}-200 opacity-50 rounded-full`}
              ></span>
              <span className="relative">{data.status}</span>
            </span>
            <h1 className="text-2xl pb-6 text-center font-bold leading-tight text-sap-blue">
              {props.title}
              {data.subject && " - "}
              {data.subject}
            </h1>
            {props.ticket && (
              <ImportanceLevel
                level={data.nivImporID}
                checkImportanceLevel={checkImportanceLevel}
              />
            )}
            {props.ticket ? (
              <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                <div className="flex px-4 py-5 sm:px-6">
                  <h3 className="flex-1 text-lg leading-6 font-medium text-gray-900">
                    {data.custmrName}
                  </h3>
                  <p className="flex-2 mt-1 max-w-2xl text-gray-500">
                    {data.createDate
                      ?.split(" ")[0]
                      .split("-")
                      .reverse()
                      .join("-")}
                  </p>
                </div>
                <div className="border-t border-gray-200">
                  <dl>
                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="font-medium text-gray-500 sm:mt-1">
                        Affecté à
                      </dt>
                      <dd className="text-center mt-1 text-gray-900 sm:mt-1">
                        {data.queue ? data.queue : data.assignee}
                      </dd>
                      <dd className="text-right mt-1 text-gray-900 sm:mt-0">
                        <span className="inline-flex items-center leading-none text-sm">
                          <svg
                            className="min-w-5 min-h-5 mr-1"
                            width="22"
                            height="22"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g fill="none" fillRule="evenodd">
                              <path
                                fill={
                                  data.priority === "H" ? "#2A4863" : "#D8D8D8"
                                }
                                d="M15.6 17.667h3.5V3h-3.5z"
                              />
                              <path
                                fill={
                                  data.priority !== "L" ? "#2A4863" : "#D8D8D8"
                                }
                                d="M9.2 6.583v11.08h3.5V6.583z"
                              />
                              <path
                                fill="#2A4863"
                                d="M2.6 17.667h3.5v-7.334H2.6z"
                              />
                            </g>
                          </svg>
                          {props.getPriority(data.priority)}
                        </span>
                      </dd>
                    </div>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="col-1 font-medium text-gray-500">
                        Type de problème / d'appel
                      </dt>
                      <dd className="text-center mt-1 text-gray-900 sm:mt-0 sm:col-span-1">
                        {data.problemTyp}
                      </dd>
                      <dd className="text-right mt-1 text-gray-900 sm:mt-0 sm:col-span-1">
                        {data.callType}
                      </dd>
                    </div>
                    {data.resolution && (
                      <div className="bg-gray-300 px-4 py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                        <dt className="font-medium text-gray-600">Solution</dt>
                        <dd className="mt-1 text-gray-900 sm:mt-0 sm:col-span-3">
                          <div className="clearfix">
                            <div className="bg-gray-100 float-right w-full mx-4 my-2 p-2 rounded-lg clearfix whitespace-pre-wrap">
                              {data.resolution}
                            </div>
                          </div>
                        </dd>
                      </div>
                    )}
                    <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                      <dt className="font-medium text-gray-500">
                        <span className="inline-block">
                          Explication du <br />
                          problème
                        </span>
                        <button
                          className="mt-1 float-right clear-both bg-white focus:outline-none hover:text-white hover:bg-sap-blue text-sap-blue font-semibold py-2 px-2 border border-gray-400 rounded-full shadow transition ease-in-out duration-150"
                          onClick={() => setEnlarge(!enlarge)}
                        >
                          <svg
                            className="h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"
                            />
                          </svg>
                        </button>
                      </dt>
                      <dd
                        className={`transition-all ease-${
                          enlarge ? "in" : "out"
                        } duration-${enlarge ? "200" : "150"} h-${
                          enlarge ? "full" : "24"
                        } text-gray-900 sm:mt-0 sm:col-span-3 overflow-hidden overflow-y-scroll`}
                      >
                        {/*<div className="mt-20 mb-16">
                        <div className="clearfix">
                          <div className="bg-gray-300 w-3/4 mx-4 my-2 p-2 rounded-lg">
                            this is a basic mobile chat layout, build with
                            tailwind css
                          </div>
                        </div>
                        <div className="clearfix">
                          <div className="bg-gray-300 w-3/4 mx-4 my-2 p-2 rounded-lg clearfix">
                            It will be used for a full tutorial about building a
                            chat app with vue, tailwind and firebase.
                          </div>
                        </div>
                        <div className="clearfix">
                          <div className="bg-green-300 float-right w-3/4 mx-4 my-2 p-2 rounded-lg clearfix">
                            check my twitter to see when it will be released.
                          </div>
                        </div>
                      </div>*/}
                        <div className="clearfix">
                          <div className="bg-gray-300 w-4/5 mx-4 my-2 p-2 rounded-lg whitespace-pre-wrap">
                            {data.descrption}
                          </div>
                        </div>
                        {/*<div className="clearfix">
                        <div className="bg-blue-300 float-right w-3/4 mx-4 my-2 p-2 rounded-lg clearfix">
                          check my twitter to see when it will be released.
                        </div>
                      </div>*/}
                      </dd>
                    </div>
                    <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                      <dt className="font-medium text-gray-500">
                        Pièces jointes
                        <label
                          htmlFor="file-upload"
                          className="float-right cursor-pointer bg-white focus:outline-none hover:text-white hover:bg-sap-blue text-sap-blue text-gray-800 font-semibold py-2 px-2 border border-gray-400 rounded-full shadow transition ease-in-out duration-150"
                          onClick={() => console.log("clicked !")}
                        >
                          <svg
                            className="h-6 w-6"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                            />
                          </svg>
                        </label>
                        <input
                          id="#file-upload"
                          name="file-upload"
                          type="file"
                          className="sr-only"
                        />
                      </dt>
                      {files.map((e) => (
                        <React.Fragment key={files.indexOf(e)}>
                          <dd
                            className={`${
                              files.length
                                ? `${
                                    files.indexOf(e) > 0 && "sm:col-start-2"
                                  } mt-1 text-gray-900 sm:mt-0 sm:col-span-2`
                                : "hidden"
                            }`}
                          >
                            <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                              <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                                <div className="w-0 flex-1 flex items-center">
                                  <svg
                                    className="flex-shrink-0 h-5 w-5 text-gray-400"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                  <span className="ml-2 flex-1 w-0 truncate">
                                    {`${e.filename.substring(
                                      0,
                                      e.filename.lastIndexOf("-+-")
                                    )}.${e.filename.split(".").pop()}`}
                                  </span>
                                </div>
                              </li>
                            </ul>
                          </dd>
                          <dd className="text-right mt-1 text-gray-900 sm:mt-0 sm:col-span-1">
                            <div className="ml-4 float-right">
                              <button
                                className="bg-white focus:outline-none hover:text-white hover:bg-sap-blue text-sap-blue font-semibold py-2 px-2 border border-gray-400 rounded-full shadow transition ease-in-out duration-150"
                                onClick={() => console.log("clicked !")}
                              >
                                <svg
                                  className="h-6 w-6"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                  />
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
                                  />
                                </svg>
                              </button>
                            </div>
                            <div className="ml-4 float-right">
                              <button
                                className="bg-white focus:outline-none hover:text-white hover:bg-sap-blue text-sap-blue font-semibold py-2 px-2 border border-gray-400 rounded-full shadow transition ease-in-out duration-150"
                                onClick={() => console.log("clicked !")}
                              >
                                <svg
                                  className="h-6 w-6"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                                  />
                                </svg>
                              </button>
                            </div>
                          </dd>
                        </React.Fragment>
                      ))}
                    </div>
                  </dl>
                </div>
              </div>
            ) : (
              <form
                autoComplete="off"
                onSubmit={handleSubmit}
                className="md:px-8 px-2 py-5"
              >
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div
                    className={`${
                      data.BusinessPartners ? "w-full md:w-2/3" : "w-full"
                    } px-3 mb-5`}
                  >
                    <div className="flex items-center border-b border-gray-800 py-2">
                      <input
                        className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                        type="text"
                        name="subject"
                        placeholder="Sujet"
                        aria-label="Full name"
                        required
                        value={subject}
                        onChange={(event) => handleChange(event)}
                        disabled={loading}
                      />
                    </div>
                    {subject && (
                      <p className="absolute text-gray-600 text-xs">Sujet</p>
                    )}
                  </div>
                  {data.BusinessPartners && (
                    <div className="w-full md:w-1/3 px-3 md:mb-0">
                      <div className="flex items-center border-b border-gray-800 py-2">
                        <input
                          className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                          type="text"
                          name="company"
                          placeholder="Société"
                          aria-label="Full name"
                          required
                          value={company}
                          onChange={(event) => handleChange(event)}
                          disabled={loading}
                          list="partnersList"
                        />
                        <datalist id="partnersList">
                          {data.BusinessPartners?.map((item, key) => (
                            <option key={key} value={item.cardName} />
                          ))}
                        </datalist>
                      </div>
                      {company && (
                        <p className="absolute text-gray-600 text-xs">
                          Société
                        </p>
                      )}
                    </div>
                  )}
                </div>
                <div className="-mx-3 mb-6">
                  <div className="w-full px-3">
                    <div className="flex items-center border-b border-gray-800 py-2">
                      <textarea
                        className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                        type="text"
                        name="description"
                        placeholder="Description"
                        aria-label="Full name"
                        required
                        rows="5"
                        value={description}
                        onChange={(event) => handleChange(event)}
                        disabled={loading}
                      />
                    </div>
                    {description && (
                      <p className="absolute text-gray-600 text-xs">
                        Description
                      </p>
                    )}
                  </div>
                </div>
                <div className="flex flex-wrap -mx-3 mb-6">
                  <div className="w-full md:w-1/3 px-3 mb-5">
                    <div className="flex items-center border-b border-gray-800 py-2">
                      <input
                        className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                        type="text"
                        name="problemType"
                        placeholder="Type de Problème"
                        aria-label="Full name"
                        required
                        value={problemType}
                        onChange={(event) => handleChange(event)}
                        disabled={loading}
                        list="problemTypesList"
                      />
                      <datalist id="problemTypesList">
                        {data.ProblemTypes?.map((item, key) => (
                          <option key={key} value={item.name} />
                        ))}
                      </datalist>
                    </div>
                    {problemType && (
                      <p className="absolute text-gray-600 text-xs">
                        Type de Problème
                      </p>
                    )}
                  </div>
                  <div className="w-full md:w-1/3 px-3 mb-5">
                    <div className="flex items-center border-b border-gray-800 py-2">
                      <input
                        className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                        type="text"
                        name="callType"
                        placeholder="Type d'Appel"
                        aria-label="Full name"
                        required
                        value={callType}
                        onChange={(event) => handleChange(event)}
                        disabled={loading}
                        list="callTypesList"
                      />
                      <datalist id="callTypesList">
                        {data.CallTypes?.map((item, key) => (
                          <option key={key} value={item.name} />
                        ))}
                      </datalist>
                    </div>
                    {callType && (
                      <p className="absolute text-gray-600 text-xs">
                        Type d'Appel
                      </p>
                    )}
                  </div>
                  <div className="w-full md:w-1/3 px-3 mb-5">
                    <div className="flex items-center border-b border-gray-800 py-2">
                      <input
                        className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                        type="text"
                        name="priority"
                        placeholder="Priorité"
                        aria-label="Full name"
                        required
                        value={priority}
                        onChange={(event) => handleChange(event)}
                        disabled={loading}
                        list="priorityList"
                      />
                      <datalist id="priorityList">
                        <option value="BASSE" />
                        <option value="MOYENNE" />
                        <option value="ÉLEVÉE" />
                      </datalist>
                    </div>
                    {priority && (
                      <p className="absolute text-gray-600 text-xs">Priorité</p>
                    )}
                  </div>
                </div>

                <div className="flex flex-wrap -mx-3">
                  <div className="w-full md:w-1/6 px-3">
                    <label
                      htmlFor="file-upload"
                      className="float-left cursor-pointer bg-white focus:outline-none hover:text-white hover:bg-sap-blue text-sap-blue text-gray-800 font-semibold py-2 px-2 border border-gray-400 rounded-full shadow transition ease-in-out duration-150"
                    >
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12"
                        />
                      </svg>
                    </label>
                    <input
                      id="file-upload"
                      name="file-upload"
                      type="file"
                      className="sr-only"
                      ref={el}
                      onChange={handleFile}
                      multiple
                    />
                  </div>
                  <div className="w-full md:w-4/6 px-3">
                    <ul
                      className={`${
                        fileParams.length
                          ? "border border-gray-200 rounded-md divide-y divide-gray-200"
                          : " hidden"
                      }`}
                    >
                      {fileParams.map((e) => (
                        <li
                          key={fileParams.indexOf(e)}
                          className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                        >
                          <div className="w-0 flex-1 flex items-center">
                            <svg
                              className="flex-shrink-0 h-5 w-5 text-gray-400"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                              fill="currentColor"
                              aria-hidden="true"
                            >
                              <path
                                fillRule="evenodd"
                                d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z"
                                clipRule="evenodd"
                              />
                            </svg>
                            <span className="ml-2 flex-1 w-0 truncate">
                              {e.name}.{e.pltype}
                            </span>
                            <svg
                              className="cursor-pointer flex-shrink-0 h-5 w-5 text-gray-400"
                              fill="none"
                              viewBox="0 0 20 20"
                              stroke="currentColor"
                              onClick={() =>
                                setFile(
                                  fileParams.filter(
                                    (item) =>
                                      fileParams.indexOf(item) !==
                                      fileParams.indexOf(e)
                                  )
                                )
                              }
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth={2}
                                d="M6 18L18 6M6 6l12 12"
                              />
                            </svg>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="w-full md:w-1/6 px-3">
                    <button
                      className="float-right bg-white focus:outline-none hover:text-white hover:bg-sap-blue hover:border-sap-blue text-sap-blue font-semibold py-2 px-2 border border-gray-400 rounded-full shadow transition ease-in-out duration-150"
                      type="submit"
                      disabled={loading}
                    >
                      <svg
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M5 13l4 4L19 7"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </form>
            )}
          </main>
        </div>
        <div
          className={`${
            comment.open ? "relative" : "absolute bottom-0 -z-1"
          } transition ease-${comment.open ? "out" : "in"} duration-${
            comment.open ? "100" : "75"
          } transform opacity-${comment.open ? "100" : "0"} scale-${
            comment.open ? "100" : "95"
          } left-0 right-0 py-6 px-4 mx-auto sm:w-full md:w-3/6`}
        >
          <div className="bg-gray-300 border-b rounded-lg py-2 px-1">
            <textarea
              className="w-12/12 px-3 py-2 text-gray-700 border rounded-lg focus:outline-none"
              rows="3"
              placeholder="Votre message"
              onChange={(event) =>
                setComment({ ...comment, text: event.target.value })
              }
            ></textarea>
            <button
              className="transform rotate-45 float-right m-1 mr-2 bg-white focus:outline-none hover:text-white hover:bg-sap-blue hover:border-sap-blue text-sap-blue font-semibold py-2 px-2 border border-gray-400 rounded-full shadow transition ease-in-out duration-150"
              onClick={() => console.log("clicked !")}
            >
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 19l9 2-9-18-9 18 9-2zm0 0v-8"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </>
  );
});

export default Ticket;
