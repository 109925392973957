export default (state = { open: false }, action) => {
  switch (action.type) {
    case "MESSAGE":
      return {
        ...state,
        open: true,
        type: action.msg.type,
        title: action.msg.title,
        text: action.msg.text,
      };
    case "SUCCESS":
      return {
        ...state,
        color: "green",
        icon: "M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z",
      };
    case "INFO":
      return {
        ...state,
        color: "yellow",
        icon: "M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z",
      };
    case "ERROR":
      return {
        ...state,
        color: "red",
        icon: "M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z",
      };
    case "CLEAR_MESSAGE":
      return {
        ...state,
        open: false,
      };
    default:
      return state;
  }
};
