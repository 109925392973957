import React, { useState, useEffect } from "react";
import API from "../../utils/API";
import { useHistory, useLocation } from "react-router-dom";

const Navbar = () => {
  const [dropdown, toggleDropdown] = useState(false),
    [mobileMenu, setMobileMenu] = useState(false),
    [data, setData] = useState({ menu: [] }),
    getData = () => {
      try {
        API.menu()
          .then((res) => {
            setData(res.data);
          })
          .catch((err) => {
            if (err.response.status === 401) {
              console.log("Wrong credentials");
              // add toast instead of alert
            }
            throw err;
          });
      } catch (error) {
        console.error(error);
      }
    },
    hide = (e) => {
      if (e && e.relatedTarget) {
        e.relatedTarget.click();
      }
      toggleDropdown(false);
    },
    history = useHistory(),
    location = useLocation(),
    redirect = (e) => {
      e.preventDefault();
      history.push(`/${e.target.name}`);
      mobileMenu && setMobileMenu(false);
    },
    logout = (e) => {
      API.logout();
      history.push("/login");
    };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <nav className="bg-sap-blue fixed top-0 w-full z-40">
        <div className="xl:container mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-between h-16">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <h2 className="text-2xl text-gray-100">O1 - SUPPORT</h2>
              </div>
              <div className="hidden xl:block">
                <div className="ml-10 flex items-baseline space-x-0">
                  {data.menu.map((e) => (
                    <button
                      key={data.menu.indexOf(e)}
                      name={e.component.toLowerCase()}
                      className={`px-5 py-2 text-sm font-medium tracking-widest uppercase ${
                        location.pathname === `/${e.component.toLowerCase()}`
                          ? "text-white bg-sap-blue border-b-2 border-white"
                          : "text-gray-100 border-b-2 border-gray-500"
                      } focus:outline-none focus:text-white focus:bg-sap-blue`}
                      onClick={redirect}
                    >
                      {e.name}
                    </button>
                  ))}
                </div>
              </div>
            </div>
            <div className="hidden xl:block">
              <div className="ml-4 flex items-center md:ml-6">
                <p className="mr-4 text-gray-200 font-medium tracking-widest uppercase">
                  {data.username}
                </p>
                <button
                  className="p-1 border-2 border-transparent text-gray-300 rounded-full hover:text-white focus:outline-none focus:text-white focus:bg-sap-yellow"
                  aria-label="Notifications"
                >
                  <svg
                    className="h-6 w-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                    />
                  </svg>
                </button>
                <div className="ml-3 relative">
                  <div>
                    <button
                      className="max-w-xs flex items-center text-sm rounded-full text-white focus:outline-none focus:shadow-solid"
                      id="user-menu"
                      aria-label="User menu"
                      aria-haspopup="true"
                      onClick={() => {
                        toggleDropdown(!dropdown);
                      }}
                      onBlur={(e) => hide(e)}
                    >
                      <svg
                        className="h-6 w-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                        />
                      </svg>
                    </button>
                  </div>
                  <div
                    className={`origin-top-right absolute right-0 mt-2 w-48 rounded-sm shadow-lg transition ease-${
                      dropdown ? "out" : "in"
                    } duration-${dropdown ? "100" : "75"} transform opacity-${
                      dropdown ? "100" : "0"
                    } scale-${dropdown ? "100" : "95"} ${!dropdown && "-z-1"}`}
                  >
                    <div
                      className="py-1 rounded-sm bg-white ring-1 ring-black ring-opacity-5"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="user-menu"
                    >
                      {/*<a
                        href="#!"
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        role="menuitem"
                      >
                        Votre Profil
                      </a>

                      <a
                        href="#!"
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        role="menuitem"
                      >
                        Paramètres
                      </a>*/}
                      <a
                        href="#!"
                        className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                        role="menuitem"
                        onClick={logout}
                      >
                        Déconnexion
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="-mr-2 flex xl:hidden">
              <p className="hidden md:block xl:hidden mr-4 mt-2 text-gray-200 font-medium tracking-widest uppercase">
                {data.username}
              </p>
              <button
                className="inline-flex items-center justify-center p-2 rounded-sm text-gray-300 hover:text-white focus:outline-none"
                onClick={() => setMobileMenu(!mobileMenu)}
                onBlur={(e) => hide(e)}
              >
                <svg
                  className={`${!mobileMenu ? "block" : "hidden"} h-6 w-6`}
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
                <svg
                  className={`${mobileMenu ? "block" : "hidden"} h-6 w-6`}
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div
          className={`lg:hidden transition ease-${
            mobileMenu ? "out" : "in"
          } duration-${mobileMenu ? "100" : "75"} transform opacity-${
            mobileMenu ? "100" : "0"
          } scale-${mobileMenu ? "100" : "95"} ${
            !mobileMenu && "-z-1 absolute hidden"
          }`}
        >
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            {data.menu.map((e) => (
              <a
                href="!#"
                key={data.menu.indexOf(e)}
                name={e.component.toLowerCase()}
                className={`block px-3 py-2 rounded-sm text-base font-medium ${
                  location.pathname === `/${e.component.toLowerCase()}`
                    ? "text-white bg-white text-sap-blue"
                    : "text-gray-200 hover:text-white"
                }`}
                onClick={redirect}
              >
                {e.name}
              </a>
            ))}
          </div>
          <div className="pb-3 border-t border-sap-yellow">
            <div className="mt-3 px-2 space-y-1">
              {/*<a
                href="#!"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-200 focus:outline-none focus:text-white focus:bg-gray-700"
                role="menuitem"
              >
                Votre Profil
              </a>

              <a
                href="#!"
                className="block px-3 py-2 rounded-md text-base font-medium text-gray-200 focus:outline-none focus:text-white focus:bg-gray-700"
                role="menuitem"
              >
                Paramètres
              </a>*/}

              <a
                href="#!"
                className="block px-3 py-2 rounded-sm text-base font-medium text-gray-200 focus:outline-none focus:text-white focus:bg-gray-700"
                role="menuitem"
                onClick={logout}
              >
                Déconnexion
              </a>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
