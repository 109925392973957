import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setComment, setValidation } from "../../../actions";

const Details = (props) => {
  const [enlarge, setEnlarge] = useState(false),
    dispatch = useDispatch(),
    open = useSelector((state) => state.missionOrders.details),
    data = useSelector((state) => state.missionOrders.data[0]),
    loading = useSelector((state) => state.missionOrders.loading),
    comment = useSelector((state) => state.missionOrders.comment),
    getcolor = props.getColor,
    valid = useSelector((state) => state.missionOrders.valid);

  useEffect(() => {
    loading && dispatch(setComment({ state: false, text: "" }));
    loading && dispatch(setValidation(false));
  }, [loading, dispatch]);

  return (
    <div
      className={`container md:pt-5 ${
        open ? "relative" : "absolute top-0 -z-1"
      } right-0 left-0 mx-auto transition ease-${
        open ? "out" : "in"
      } duration-${open ? "100" : "75"} transform opacity-${
        open ? "100" : "0"
      } scale-${open ? "100" : "95"}`}
    >
      {loading && (
        <div className="lds-facebook">
          <div></div>
          <div></div>
          <div></div>
        </div>
      )}
      <div
        className={`shadow${
          loading ? "-2xl" : ""
        } overflow-hidden border-b border-gray-200 sm:rounded-sm mx-auto sm:w-full md:w-4/6`}
      >
        <main
          className={`bg-white shadow mx-auto py-6 px-4 sm:px-6 lg:px-8 transition ease-${
            loading ? "out" : "in"
          } duration-${loading ? "300" : "200"} transform opacity-${
            loading ? "0" : "100"
          }`}
        >
          <span
            className={`float-right mt-1 ml-1 relative inline-block px-3 py-1 font-semibold text-blue-900 leading-tight`}
          >
            <span
              aria-hidden
              className={`absolute inset-0 bg-${props.getColor(data?.status)}-200 opacity-50 rounded-full`}
            ></span>
            <span className={`relative capitalize text-${props.getColor(data?.status)}-900`}>
              {data?.status}
            </span>
          </span>
          <h1 className="text-2xl pb-6 text-center font-bold leading-tight text-sap-blue">
            {"#" + data?.clgCode + " - " + data?.details}
          </h1>
          <div className="bg-white shadow overflow-hidden sm:rounded-lg">
            <div className="flex px-4 py-5 sm:px-6">
              <h3 className="flex-1 text-lg leading-6 font-medium text-gray-900">
                {data?.cardName}
              </h3>
              <p className="flex-2 mt-1 max-w-2xl text-gray-500">
                {data?.beginDate?.split(" ")[0].split("-").reverse().join("-")}
              </p>
            </div>
            <div className="border-t border-gray-200">
              <dl>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="font-medium text-gray-500 sm:mt-1">
                    Traité par
                  </dt>
                  <dd className="text-center mt-1 text-gray-900 sm:mt-1">
                    {data?.attendUser}
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="font-medium text-gray-500 sm:mt-1">
                    Début
                  </dt>
                  <dd className="text-center mt-1 text-gray-900 sm:mt-1">
                    { data?.beginDate.split(' ')[0] } : { data?.beginTime }
                  </dd>
                </div>
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="font-medium text-gray-500 sm:mt-1">
                    Fin
                  </dt>
                  <dd className="text-center mt-1 text-gray-900 sm:mt-1">
                    { data?.endDate.split(' ')[0] } : { data?.endTime }
                  </dd>
                </div>
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                  <dt className="font-medium text-gray-500">
                    <span className="inline-block mt-3">Description</span>
                    <button
                      className="mt-1 float-right clear-both bg-white focus:outline-none hover:text-white hover:bg-sap-blue text-sap-blue font-semibold py-2 px-2 border border-gray-400 rounded-full shadow transition ease-in-out duration-150"
                      onClick={() => setEnlarge(!enlarge)}
                    >
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M4 8V4m0 0h4M4 4l5 5m11-1V4m0 0h-4m4 0l-5 5M4 16v4m0 0h4m-4 0l5-5m11 5l-5-5m5 5v-4m0 4h-4"
                        />
                      </svg>
                    </button>
                  </dt>
                  <dd
                    className={`transition-all ease-${
                      enlarge ? "in" : "out"
                    } duration-${enlarge ? "200" : "150"} h-${
                      enlarge ? "full" : "24"
                    } text-gray-900 sm:mt-0 sm:col-span-3 overflow-hidden overflow-y-scroll`}
                  >
                    <div className="bg-gray-300 w-full overflow-hidden mx-4 my-2 p-2 rounded-lg whitespace-pre-wrap">
                      {data?.notes}
                    </div>
                  </dd>
                </div>
                {data?.comment.length ? (
                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                    <dt className="font-medium text-gray-500 sm:mt-4">
                      Commentaire
                    </dt>
                    <dd className="text-gray-900 sm:col-span-3 pr-8">
                      <div className="bg-blue-300 w-full mx-4 my-2 p-2 rounded-lg">
                        {data?.comment}
                      </div>
                    </dd>
                  </div>
                ) : null}
                {data?.status === "réalisée" && !data?.comment.length ? (
                  <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-4 sm:gap-4 sm:px-6">
                    <dt className="flex flex-row">
                      <div className="select-none my-2 mr-2 font-medium text-gray-500">
                        Commentaire
                      </div>
                      <div className="bg-white border-2 rounded-sm border-gray-400 w-6 h-6 flex flex-shrink-0 justify-center items-center m-2 focus-outline-none">
                        <input
                          type="checkbox"
                          name="comment"
                          className="opacity-0 absolute"
                          onClick={() =>
                            dispatch(
                              setComment({ text: "", state: !comment.state })
                            )
                          }
                        />
                        {comment.state && (
                          <svg
                            className="fill-current w-4 h-4 text-sap-blue pointer-events-none"
                            viewBox="0 0 20 20"
                          >
                            <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                          </svg>
                        )}
                      </div>
                    </dt>
                    <dd className="text-gray-900 sm:col-span-3 flex flex-row-reverse">
                      {comment.state ? (
                        <textarea
                          className="my-1 mx-3 p-3 w-full resize-y border rounded-md focus:outline-none focus:ring focus:border-sap-blue"
                          placeholder="Saisissez votre commentaire..."
                          onChange={(event) =>
                            dispatch(
                              setComment({
                                ...comment,
                                text: event.target.value,
                              })
                            )
                          }
                        ></textarea>
                      ) : (
                        <>
                          <div className="bg-white border-2 rounded-sm border-gray-400 w-6 h-6 flex flex-shrink-0 justify-center items-center my-2 ml-2 focus-outline-none">
                            <input
                              type="checkbox"
                              name="valid"
                              className="opacity-0 absolute"
                              onClick={() => dispatch(setValidation(!valid))}
                            />
                            {valid && (
                              <svg
                                className="fill-current w-4 h-4 text-sap-blue pointer-events-none"
                                viewBox="0 0 20 20"
                              >
                                <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                              </svg>
                            )}
                          </div>
                          <div className="select-none m-2 font-medium text-gray-500">
                            Valider cet ordre de mission
                          </div>
                        </>
                      )}
                    </dd>
                  </div>
                ) : null}
              </dl>
              {data?.status === "réalisée" &&
              (comment.state || data?.comment.length) ? (
                <div className="mt-6 flex flex-row-reverse">
                  <label className="flex mr-4 mb-4">
                    <div className="select-none m-2 font-medium text-gray-500">
                      Valider cet ordre de mission
                    </div>
                    <div className="bg-white border-2 rounded-sm border-gray-400 w-6 h-6 flex flex-shrink-0 justify-center items-center m-2 focus-outline-none">
                      <input
                        type="checkbox"
                        name="valid"
                        className="opacity-0 absolute"
                        onClick={() => dispatch(setValidation(!valid))}
                      />
                      {valid && (
                        <svg
                          className="fill-current w-4 h-4 text-sap-blue pointer-events-none"
                          viewBox="0 0 20 20"
                        >
                          <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                        </svg>
                      )}
                    </div>
                  </label>
                </div>
              ) : null}
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default Details;
