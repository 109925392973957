import React, { useState, useEffect, useCallback } from "react";
import API from "../utils/API.js";
import { Route, Redirect } from "react-router-dom";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

export const PrivateRoute = ({ component: Component, ...rest }) => {
  const [privateRoute, setPrivateRoute] = useState(null),
   checkAuth = useCallback(() => {
    if (!API.isAuth()) {
      setPrivateRoute(<Redirect to="/" />);
    } else {
      setPrivateRoute(
        <>
          <Navbar />
          <Route {...rest} component={Component} />
          <Footer />
        </>
      );
    }
  }, [rest, Component]);

  useEffect(checkAuth, [Component]);

  return privateRoute;
};
