import React, { useState, useEffect } from "react";

const ImportanceLevel = (props) => {
  const [nivImpor, setNivImpor] = useState("1"),
    [style, setStyle] = useState({}),
    { checkImportanceLevel } = props,
    handleRange = (value) => {
      setNivImpor(value);
    },
    percent = (value) => {
      return ((value - 1) / (5 - 1)) * 100;
    },
    color = (percent) => {
      switch (percent) {
        case 25:
          return "#8B8EA7";
        case 50:
          return "#82CFD0";
        case 75:
          return "#F9E784";
        case 100:
          return "#FF707E";
        default:
          return "#FFF";
      }
    };

  useEffect(() => {
    document.getElementById("myRange").oninput = function () {
      const value = percent(this.value);
      this.style.background = `linear-gradient(to right, ${color(
        value
      )} 0%, ${color(value)} ${value}%, #FFF ${value}%, white 100%)`;
      this.style.border = `1px solid ${color(value)}`;
    };
  });
  useEffect(() => {
    setNivImpor(JSON.stringify(5 - props.level + 1));
    const value = percent(5 - props.level + 1);
    setStyle({
      background: `linear-gradient(to right, ${color(value)} 0%, ${color(
        value
      )} ${percent(5 - props.level + 1)}%, #FFF ${value}%, white 100%)`,
      border: `1px solid ${color(value)}`,
    });
  }, [props.level]);
  useEffect(() => {
    if (props.level) {
      if (nivImpor !== JSON.stringify(5 - props.level + 1)) {
        checkImportanceLevel(true, JSON.stringify(5 - nivImpor + 1));
      } else {
        checkImportanceLevel(false, JSON.stringify(5 - nivImpor + 1));
      }
    }
  }, [nivImpor, props.level, checkImportanceLevel]);
  return (
    <div className="px-6 w-full mb-5">
      <input
        id="myRange"
        className="h-2 w-full shadow rounded-full cursor-pointer border border-solid outline-none appearance-none"
        type="range"
        min="1"
        max="5"
        step="1"
        style={style}
        value={nivImpor}
        onChange={(event) => handleRange(event.target.value)}
      />
      <div className="flex justify-between mt-2 text-xs text-gray-600">
        <span className="w-12 text-left">Annulé</span>
        <span className="w-12 text-center">Suspendu</span>
        <span className="w-16 text-center">Non urgent</span>
        <span className="w-12 text-center">Standard</span>
        <span className="w-12 text-right">Prioritaire</span>
      </div>
    </div>
  );
};

export default ImportanceLevel;
