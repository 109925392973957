import React, { useEffect } from "react";

const ScrollTop = () => {
  const scrollToTop = () => {
    const c = document.documentElement.scrollTop || document.body.scrollTop;
    if (c > 0) {
          window.requestAnimationFrame(scrollToTop );
          window.scrollTo(0, c - c / 8);
        }

  };

  useEffect(() => {
    window.onscroll = () => {
      if (
        document.body.scrollTop > 185 ||
        document.documentElement.scrollTop > 185
      ) {
        document.getElementById("Btn").style.display = "block";
      } else {
        document.getElementById("Btn").style.display = "none";
      }
    };
  }, []);

  return (
    <button
      id="Btn"
      className="fixed hidden z-40 right-5 bottom-5 bg-white focus:outline-none hover:text-white hover:bg-sap-yellow hover:border-sap-yellow text-sap-blue font-semibold py-2 px-2 border border-gray-400 rounded-full shadow transition ease-in-out duration-150"
      onClick={scrollToTop}
    >
      <svg
        className="h-6 w-6"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M5 15l7-7 7 7"
        />
      </svg>
    </button>
  );
};

export default ScrollTop;
