import React from "react";
import { useSelector } from "react-redux";

const Message = () => {
  const message = useSelector((state) => state.message);

  return (
    <div
      className={`transform translate-x-${message.open ? "0" : "96"} opacity-${
        message.open ? "100" : "0"
      } transition duration-500 ease-in-out fixed top-0 right-0 m-8 w-5/6 md:w-full max-w-sm z-50`}
    >
      <input
        type="checkbox"
        readOnly
        checked={message.open}
        className="hidden"
        id="toast"
      />
      <label
        className={`bg-${message.color}-100 border-t-4 border-${message.color}-500 rounded-b text-${message.color}-900 cursor-pointer flex float-right w-full lg:w-5/6 p-2 h-20 shadow-md`}
        title="close"
        htmlFor="toast"
      >
        <div className="flex ml-2 pt-1">
          <div className="py-1 pr-3">
            <svg
              className={`h-10 text-${message.color}-500`}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d={message.icon}
              />
            </svg>
          </div>
          <div>
            <p className="font-bold">{message.title}</p>
            <p className="text-sm">{message.text}</p>
          </div>
        </div>
      </label>
    </div>
  );
};

export default Message;
