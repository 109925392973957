import React, { useState, useEffect } from "react";
import Actions from "../../components/OM/Actions";
import List from "../../components/OM/List";
import Details from "../../components/OM/Details";
import Filters from "../../components/OM/Filters";
import logo from "../../assets/logo_o1_sap.png";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { toggleFilters, validateMissionOrder } from "../../actions";
import { getMissionOrders } from "../../actions";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MissionsOrders = () => {
  const isFiltersPanelOpen = useSelector(
      (state) => state.missionOrders.filters.isOpen
    ),
    MySwal = withReactContent(Swal),
    [reload, setReload] = useState(false),
    [rows, setRows] = useState({ fetch: 0, next: 20 }),
    [typing, setTyping] = useState(""),
    [status, setStatus] = useState(""),
    [client, setClient] = useState({ cardCode: "", name: "" }),
    [search, setSearch] = useState(""),
    [sort, setSort] = useState({ dir: "ClgCode", order: "DESC" }),
    [closed, setClosed] = useState("N"),
    [selectedOM, setSelectedOM] = useState([]),
    loading = useSelector((state) => state.missionOrders.loading),
    getColor = (status) => {
      switch(status) {
        case "réalisée":
          return "green";
        case "OM signé":
        case "OM auto validé":
          return "blue";
        case "planifiée":
        case "confirmée":
          return "yellow";
        case "réservé":
          return "red";
      }
    },
    handleRows = (e) => {
      const { name, value } = e.currentTarget;
      setRows((rows) => ({ ...rows, [name]: Number(value) }));
    },
    handleStatus = (e) => {
      setStatus(e);
      setClosed("N");
    },
    handleClient = (e) => {
      setClient({ cardCode: e.cardCode, name: e.cardName });
    },
    handleClosed = (e) => {
      setClosed(e);
      setStatus("");
    },
    clearFilters = () => {
      setRows({ fetch: 0, next: 20 });
      setStatus("");
      setClient({ cardCode: "", name: "" });
      setTyping("");
      setSearch("");
      setSort({ dir: "", order: "" });
      setClosed('N');
    },
    signOMs = (OMList) => {
      OMList.forEach(OM => {
        console.log(OM);
        dispatch(validateMissionOrder({clgCode: OM, comment: '', valid: true}))
      });
    },
    handleOrder = (dir = "", order = "") => {
      console.log('%cSort', 'font-size: 3em;')
      console.log('dir:', dir, 'order: ', order);
      setSort({ dir: dir, order: order });
    },
    dispatch = useDispatch();

    useEffect(() => {
      console.log('%cGetting Data', 'font-size: 2em; color: green;')
      dispatch(getMissionOrders({
        clgCode: "",
        fetch: rows.fetch,
        next: rows.next,
        status: status,
        cardCode: client.cardCode,
        closed: closed,
        dir: sort.dir,
        order: sort.order,
      }));
    }, [dispatch, rows, status, client, closed, sort, reload]); 

  return (
    <>
      <header className="bg-white mt-16 md:mt-0 md:fixed top-16 w-full z-10 shadow">
        <div className="flex container mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <h1
            className={`flex-1 text-3xl font-bold leading-tight text-sap-blue tracking-widest uppercase transition ease-${
              !isFiltersPanelOpen ? "in" : "out"
            } duration-${!isFiltersPanelOpen ? "500" : "500"} opacity-${
              !isFiltersPanelOpen ? "100" : "0"
            } transform-gpu translate-x-${!isFiltersPanelOpen ? "0" : "1/4"}`}
          >
            Ordres de missions
            <button
              className="ml-3 bg-white focus:outline-none hover:text-white hover:bg-sap-yellow hover:border-sap-yellow text-sap-blue font-semibold py-2 px-2 border border-gray-400 rounded-full shadow transition ease-in-out duration-150"
              onClick={() => dispatch(toggleFilters())}
            >
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
                />
              </svg>
            </button>
          </h1>
          {/* Accepter plusieurs OM en même temps */}
          <div className={`${selectedOM.length && (status == "réalisée" || status == "") && closed == 'N'  ? "" : "hidden" } flex`}>
            <h2 className="md:block text-lg font-semibold tracking-widest text-sap-blue uppercase text-right md:mt-2 mr-4">
              Valider les OM</h2>
            <button
              className="z-20 flex-2 mr-3 bg-white focus:outline-none hover:text-white hover:bg-sap-yellow hover:border-sap-yellow text-sap-blue font-semibold py-2 px-2 border border-gray-400 rounded-full shadow transition ease-in-out duration-150"
              onClick={ () => {
                MySwal.fire({
                  icon: "warning",
                  title: 'Confirmer l\'opération',
                  text: `Vous allez valider ${selectedOM.length} OM`,
                  confirmButtonText: 'Oui',
                  confirmButtonColor: 'green',
                  cancelButtonText: 'Non',
                  cancelButtonColor: 'Red',
                  showCancelButton: true
                }).then((result) => {
                  if(result.isConfirmed) {
                    console.log("%cSigning OMs", 'color: orange; font-size: 1.2em;');
                    signOMs(selectedOM);
                    setSelectedOM([]);
                    setReload(!reload);
                  } 
                });
              }}
              disabled={loading}
            >
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d={"M5 13l4 4L19 7"}
                />
              </svg>
            </button>
          </div>
          <Actions 
            rows = { rows.next }
            status = { status.value }
            handleStatus={handleStatus}
            typing={ typing }
            client = { client.name }
            handleClient = { handleClient }
          />
          <img
            className="h-10 flex-3 hidden md:block"
            src={logo}
            alt="compagny_logo"
          />
        </div>
      </header>
      <div className="flex-1 md:mt-36 bg-gradient-to-b from-sap-blue via-sap-blue-light to-sap-gray p-3">
        <main className="antialiased font-sans">
          <List 
            rows = { rows }
            handleRows = { handleRows }
            status = { status }
            handleStatus={handleStatus}
            typing={ typing }
            client = { client.name }
            selectedOM = { selectedOM }
            setSelectedOM = { setSelectedOM }
            closed = { closed }
            handleClosed = { handleClosed }
            sort = { sort }
            handleOrder = { handleOrder }
            getColor = { getColor }
          />
          <Details
            getColor = { getColor }
          />
          <Filters 
            rows = { rows.next }
            handleRows = { handleRows }
            status = { status }
            typing={ typing }
            setTyping={ setTyping }
            handleStatus={handleStatus}
            client = { client.name }
            handleClient = { handleClient }
            clearFilters = { clearFilters }
          />
        </main>
      </div>
    </>
  );
};

export default MissionsOrders;
