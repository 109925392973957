import React, { useState, useRef, useEffect } from "react";
import API from "../../utils/API";
import { useHistory } from "react-router-dom";
import logo from "../../assets/logo_o1_sap.png";
import bkgrd from "../../assets/bckgrd_login.jpg";
import MessageOld from "../MessageOld";

const useFocus = () => {
    const htmlElRef = useRef(null);
    const setFocus = () => {
      htmlElRef.current && htmlElRef.current.focus();
    };
    return [htmlElRef, setFocus];
  },
  Login = () => {
    const [inputs, setInputs] = useState({
        email: "",
        password: "",
        connected: false,
      }),
      [submitted, setSubmitted] = useState(false),
      [message, setMessage] = useState({}),
      [inputRef, setInputFocus] = useFocus(),
      { email, password, connected } = inputs,
      history = useHistory(),
      handleChange = (e) => {
        const { name, value } = e.target;
        setInputs((inputs) => ({ ...inputs, [name]: value }));
      },
      toggleState = (e) => {
        const { name } = e.target;
        setInputs((inputs) => ({ ...inputs, [name]: !inputs[name] }));
      },
      handleSubmit = async (e) => {
        e.preventDefault();
        setSubmitted(true);
        try {
          API.login(inputs)
            .then((response) => {
              response.status === 200 && history.push("/dashboard");
            })
            .catch((err) => {
              (err.response.status >= 400 || err.response.status === 500) &&
                setMessage({
                  type: "error",
                  title: "Erreur",
                  text: err.response.data.text,
                });
              setInputs({
                email: "",
                password: "",
              });
              setSubmitted(false);
              setInputFocus();
              throw err;
            });
        } catch (error) {
          console.error(error);
        }
      };
    useEffect(() => {
      API.isAuth() && history.push("/dashboard");
    }, [history]);
    return (
      <>
        <MessageOld message={message} cleanMsg={setMessage} />
        <div className="min-h-screen flex items-center justify-center pb-20 md:py-12 px-2 lg:px-8 bg-gradient-to-b from-sap-blue via-sap-blue-light to-sap-gray">
          <div className="lg:pb-20 xl:w-1/2 lg:w-3/4 sm:w-full">
            <div className=" rounded-sm overflow-hidden shadow-lg">
              <div className="relative">
                <img
                  className="object-cover h-56 lg:h-64 w-full"
                  src={bkgrd}
                  alt="tech"
                />
                <h2 className="absolute inset-x-0 bottom-0 pb-5 text-center text-5xl shadow-2xl text-gray-100">
                  O1 - SUPPORT
                </h2>
              </div>
              <div className="relative px-6 py-4 bg-white">
                <img
                  className="absolute bottom-16 right-2 md:bottom-5 md:left-4 -mt-4 -ml-2 pl-3 py-3 h-20 md:h-24"
                  src={logo}
                  alt="compagny_logo"
                />
                <form
                  autoComplete="off"
                  className="mt-3 mx-auto lg:w-1/2 md:w-1/2 sm:w-full"
                  onSubmit={handleSubmit}
                >
                  <input type="hidden" name="remember" value="true" />
                  <div className="rounded-md shadow-sm">
                    <div className="flex flex-wrap -mx-3 mb-6">
                      <div className="w-full px-3">
                        <div className="flex items-center border-b border-gray-800 py-2">
                          <input
                            className="appearance-none bg-transparent border-none w-full text-sap-blue mr-3 py-1 px-2 leading-tight focus:outline-none"
                            type="text"
                            name="email"
                            placeholder="Email"
                            aria-label="Full name"
                            required
                            autoFocus
                            ref={inputRef}
                            value={email}
                            onChange={(event) => handleChange(event)}
                          />
                        </div>
                        {email && (
                          <p className="absolute text-sap-blue text-xs">
                            Email
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="flex flex-wrap -mx-3 mb-6">
                      <div className="w-full px-3">
                        <div className="flex items-center border-b border-gray-800 py-2">
                          <input
                            className="appearance-none bg-transparent border-none w-full text-sap-blue mr-3 py-1 px-2 leading-tight focus:outline-none"
                            type="password"
                            name="password"
                            placeholder="Mot de passe"
                            aria-label="Full name"
                            required
                            value={password}
                            onChange={(event) => handleChange(event)}
                          />
                        </div>
                        {password && (
                          <p className="absolute text-sap-blue text-xs">
                            Mot de passe
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 flex flex-wrap items-center justify-between">
                    <label className="flex justify-start items-start pt-2">
                      <div className="bg-white border-2 rounded-sm border-gray-400 w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2 focus-outline-none">
                        <input
                          type="checkbox"
                          name="connected"
                          className="opacity-0 absolute"
                          onClick={(event) => toggleState(event)}
                        />
                        {connected && (
                          <svg
                            className="fill-current w-4 h-4 text-sap-blue pointer-events-none"
                            viewBox="0 0 20 20"
                          >
                            <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                          </svg>
                        )}
                      </div>
                      <div className="select-none ml-2 text-sap-blue">
                        Restez connecté
                      </div>
                    </label>
                  </div>
                  <div className="my-3 pt-3">
                    <button
                      type="submit"
                      className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-sm text-white bg-sap-yellow hover:bg-sap-light focus:outline-none focus:border-sap-blue focus:ring-gray active:bg-sap-blue transition duration-150 ease-in-out"
                    >
                      <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                        {submitted ? (
                          <svg
                            className="animate-spin h-5 w-5 mr-3 ..."
                            viewBox="0 0 24 24"
                          ></svg>
                        ) : (
                          <svg
                            className="h-5 w-5 text-sap-blue group-hover:text-sap-gray transition ease-in-out duration-150"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                          >
                            <path
                              fillRule="evenodd"
                              d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                              clipRule="evenodd"
                            />
                          </svg>
                        )}
                      </span>
                      SUIVANT
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

export default Login;
