import React, { useState, useEffect, useCallback } from "react";
import "../../utils/animations.css";

const MessageOld = (props) => {
  const [message, setMessage] = useState({}),
    [state, setState] = useState(false),
    toggleState = useCallback(() => {
      setState(true);
      const timer = setTimeout(() => props.cleanMsg({}), 1000);
      return () => clearTimeout(timer);
    }, [props]);
  useEffect(() => {
    if (Object.keys(props.message).length) {
      setState(false);
      let data;
      switch (props.message.type) {
        case "error":
          data = {
            ...props.message,
            color: "red",
            icon: "M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z",
            state: "visible",
          };
          break;
        case "success":
          data = {
            ...props.message,
            color: "green",
            icon: "M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z",
            state: "visible",
          };
          break;
        case "info":
          data = {
            ...props.message,
            color: "yellow",
            icon: "M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z",
            state: "visible",
          };
          break;
        default:
          data = {};
      }
      setMessage(data);
      const timer = setTimeout(() => toggleState(), 2000);
      return () => clearTimeout(timer);
    }
  }, [props.message, toggleState]);
  return (
    <div
      className={`${
        Object.keys(props.message).length ? "alert-toast" : "hidden"
      } fixed top-0 right-0 m-8 w-5/6 md:w-full max-w-sm z-50`}
    >
      <input
        type="checkbox"
        readOnly
        checked={state}
        onClick={() => toggleState()}
        className="hidden"
        id="toast"
      />
      <label
        className={`bg-${message.color}-100 border-t-4 border-${message.color}-500 rounded-b text-${message.color}-900 cursor-pointer flex float-right w-full lg:w-5/6 p-2 h-20 shadow-md`}
        title="close"
        htmlFor="toast"
      >
        <div className="flex ml-2 pt-1">
          <div className="py-1 pr-3">
            <svg
              className={`h-10 text-${message.color}-500`}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d={message.icon}
              />
            </svg>
          </div>
          <div>
            <p className="font-bold">{message.title}</p>
            <p className="text-sm">{message.text}</p>
          </div>
        </div>
      </label>
    </div>
  );
};

export default MessageOld;
