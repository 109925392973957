import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getMissionOrders } from "../../../actions";

const List = (props) => {
  const [params, setParams] = useState({
      clgCode: "",
      fetch: props.rows.fetch,
      next: props.rows.next,
      rows: props.rows.next,
      status: props.status,
      closed: props.closed,
      dir: props.sort.dir,
      order: props.sort.order,
    }),
    dispatch = useDispatch(),
    list = useSelector((state) => state.missionOrders.data),
    total = useSelector((state) => state.missionOrders.total),
    loading = useSelector((state) => state.missionOrders.loading),
    open = useSelector((state) => state.missionOrders.details),
    getColor = props.getColor,
    isOpen = useSelector((state) => state.missionOrders.filters.isOpen);
  return (
    !open && (
      <>
        <div
          className={`hidden xl:block p-4 container mx-auto transition ease-${
            isOpen ? "in" : "out"
          } duration-${isOpen ? "500" : "500"} ${
            isOpen ? "md:w-3/4" : "container"
          } md:m${isOpen ? "l-16" : "x-auto"} transform-gpu ${
            isOpen ? "md:translate-x-1/4" : "translate-x-0"
          }`}
        >
          <ul className="flex OM_tabs-container">
            <li className="flex-1">
              <button
                className={`OM_tabs ${
                  props.status == "réalisée"
                    ? "active"
                    : ""
                } font-semibold`}
                name="status"
                value="réalisée"
                onClick={(event) => props.status == "réalisée" ? props.handleStatus("") : props.handleStatus(event.target.value)}
              >
                À valider
              </button>
            </li>
            <li className="flex-1">
              <button
                className={`OM_tabs ${
                  props.status == "A Valider"
                    ? "active"
                    : ""
                } font-semibold`}
                name="status"
                value="A Valider"
                onClick={(event) => props.status == "A Valider" ? props.handleStatus("") : props.handleStatus(event.target.value)}
              >
                Validés
              </button>
            </li>
            <li className="flex-1">
              <button
                  className={`OM_tabs ${
                    props.closed == "Y"
                      ? "active"
                      : ""
                  } font-semibold`}
                  name="closed"
                  value="Y"
                  onClick={(event) => props.closed == 'Y' ? props.handleClosed('N') : props.handleClosed(event.target.value)}
                >
                  Fermées
              </button>
            </li>
          </ul>
          <div className="OM_table">
            {!list.length && loading && (
              <div className="lds-facebook">
                <div></div>
                <div></div>
                <div></div>
              </div>
            )}
            {!list.length && !loading && (
              <h2 className="absoluteCentered text-lg font-bold leading-tight text-sap-blue">
                Il n'y a pas de résultat pour cette recherche
              </h2>
            )}
            <table className="w-12/12 mx-auto leading-normal">
              <thead>
                <tr>
                  <th className="bg-sap-gray text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                  </th>
                  <th className="bg-sap-gray text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    <b>#</b>
                    <svg
                      className="h-6 w-6 float-right cursor-pointer"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      onClick={() =>
                        props.handleOrder(
                          "T0.ClgCode",
                          props.sort.order === "ASC" ? "DESC" : "ASC"
                        )
                      }
                    >
                      {props.sort.dir == "T0.ClgCode" ? (
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d={ props.sort.order == "ASC" ? "M8 6l-4 4-4-4" : "M8 9l4-4 4 4" }
                        />
                      ) : (
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M8 9l4-4 4 4m0 6l-4 4-4-4"
                        />
                      )}
                        
                    </svg>
                  </th>
                  <th className="px-2 py-3 pl-5 border-b-2 border-gray-300 bg-sap-gray text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    <b>Société</b>
                  </th>
                  <th className="px-2 py-3 border-b-2 border-gray-300 bg-sap-gray text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    <b>Sujet</b>
                  </th>
                  <th className="px-2 py-3 border-b-2 border-gray-300 bg-sap-gray text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    <b>Remarques</b>
                  </th>
                  <th className="px-2 py-3 border-b-2 border-gray-300 bg-sap-gray text-center text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    <b>Début</b>
                    <svg
                      className="h-6 w-6 float-right cursor-pointer"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      onClick={() =>
                        props.handleOrder(
                          "T0.recontact",
                          props.sort.order === "ASC" ? "DESC" : "ASC"
                        )
                      }
                    >
                      {props.sort.dir == "T0.recontact" ? (
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d={ props.sort.order == "ASC" ? "M8 6l-4 4-4-4" : "M8 9l4-4 4 4" }
                        />
                      ) : (
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M8 9l4-4 4 4m0 6l-4 4-4-4"
                        />
                      )}
                        
                    </svg>
                  </th>
                  <th className="px-2 py-3 border-b-2 border-gray-300 bg-sap-gray text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    <b>Traité par</b>
                  </th>
                  <th className="px-2 py-3 pr-10 border-b-2 border-gray-300 bg-sap-gray text-right text-xs font-semibold text-gray-600 uppercase tracking-wider">
                    <b>Statut</b>
                  </th>
                </tr>
              </thead>
              <tbody
                className={`${loading && "shiny"} transition ease-${
                  list.length ? "out" : "in"
                } duration-${list.length ? "100" : "75"} transform opacity-${
                  list.length ? "100" : "0"
                } scale-${list.length ? "100" : "95"}`}
              >
                {!list.length && <tr className="h-96"></tr>}
                {list.map((e) => (
                  <tr
                    key={list.indexOf(e)}
                    className={`cursor-pointer transition duration-500 ease-in-out list-item`}
                    onClick={() =>
                      dispatch(
                        getMissionOrders({ ...params, clgCode: e.clgCode, closed: e.closed })
                      )
                    }
                  >
                    <td
                      className={`py-4 px-2 border-gray-200`}
                      onClick={(event) => {event.stopPropagation();}}
                    >
                      <input type="checkbox" name="" id="" 
                        id={e.clgCode}
                        onClick={(event) => {
                          let newArray = [...props.selectedOM, event.target.id];
                          if(props.selectedOM.includes(event.target.id)) {
                            newArray = newArray.filter(OM => OM !== event.target.id);
                          }
                          props.setSelectedOM(newArray);
                        }}
                      />
                    </td>
                    <td
                      className={`py-4 px-2 pl-4 border-gray-200`}
                    >
                      <p className="text-gray-800 whitespace-nowrap text-left">
                        {e.clgCode}
                      </p>
                    </td>
                    <td
                      className={`py-4 px-2 border-gray-200 text-sm`}
                    >
                      <div className="flex items-center">
                        <div className="ml-3">
                          <p className="text-gray-900 whitespace-nowrap overflow-hidden truncate w-32">
                            {e.cardName}
                          </p>
                        </div>
                      </div>
                    </td>
                    <td
                      className={`py-4 px-2 border-gray-200`}
                    >
                      <p className="text-gray-900 whitespace-nowrap overflow-hidden truncate w-60 text-left" title={`${e.details}`}>
                        {e.details}
                      </p>
                    </td>
                    <td
                      className={`py-4 px-2 border-gray-200 text-sm`}
                    >
                      <p className="text-gray-900 whitespace-nowrap text-left w-60 truncate" title={`${e.notes}`}>
                        {e.notes}
                      </p>
                    </td>
                    <td
                      className={`py-4 px-2 border-gray-200 text-center text-sm`}
                    >
                      <p className="text-gray-900 whitespace-nowrap">
                        {e.beginDate.split(" ")[0].split("-").reverse().join("-")}
                      </p>
                    </td>
                    <td
                      className={`py-4 px-2 border-gray-200 text-left text-sm`}
                    >
                      <p className="text-gray-900 whitespace-nowrap truncate mx-auto">
                        {e.attendUser}
                      </p>
                    </td>
                    <td
                      className={`py-4 px-2 border-gray-200 text-left text-sm`}
                    >
                      <span className={`relative text-${getColor(e.status)}-900 px-3 py-1 whitespace-nowrap truncate mx-auto`}>
                        <span
                          aria-hidden
                          className={`absolute inset-0 bg-${getColor(e.status)}-200 opacity-50 rounded-full`}
                        ></span>
                        <span className="relative capitalize">
                          { e.status }
                        </span>
                      </span>


                      {e.comment.length ? (
                        <svg
                          className="h-5 w-5 absolute right-3 bottom-4"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
                          />
                        </svg>
                      ) : null}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="w-12/12 mx-auto bg-white px-4 py-3 flex items-center border-gray-200 sm:px-6">
              <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                <div>
                  <p className="text-sm leading-5 text-gray-700">
                    Résultat :{" "}
                    <span className="font-medium">
                      {total ? props.rows.fetch + 1 : 0}
                    </span>{" "}
                    sur{" "}
                    <span className="font-medium">
                      {props.rows.fetch + props.rows.next > total
                        ? total
                        : props.rows.fetch + props.rows.next}
                    </span>{" "}
                    de <span className="font-medium">{total}</span> au total
                  </p>
                </div>
                <div>
                  <nav className="relative z-0 inline-flex shadow-sm">
                    {/* First */}
                    <button
                      disabled={!props.rows.fetch || loading}
                      name="fetch"
                      value={ 0 }
                      onClick={(event) => props.handleRows(event)}
                      className={`relative inline-flex items-center px-2 py-2 rounded-l-sm disabled:opacity-50 ${
                        !props.rows.fetch && "pointer-events-none"
                      } border border-gray-400 bg-white text-sm leading-5 font-medium focus:outline-none hover:text-white hover:bg-sap-yellow hover:border-sap-yellow text-sap-blue transition ease-in-out duration-150`}
                      aria-label="Previous"
                    >
                      Début
                    </button>
                    {/* Previous */}
                    <button
                      disabled={!props.rows.fetch || loading}
                      name="fetch"
                      value={
                        props.rows.fetch - props.rows.next <= 0
                          ? 0
                          : props.rows.fetch - props.rows.next
                      }
                      onClick={(event) => props.handleRows(event)}
                      className={`relative inline-flex items-center px-2 py-2 rounded-l-sm disabled:opacity-50 ${
                        !props.rows.fetch && "pointer-events-none"
                      } border border-gray-400 bg-white text-sm leading-5 font-medium focus:outline-none hover:text-white hover:bg-sap-yellow hover:border-sap-yellow text-sap-blue transition ease-in-out duration-150`}
                      aria-label="Previous"
                    >
                      <svg
                        className="h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                    {/* Next */}
                    <button
                      disabled={total - props.rows.fetch <= props.rows.next || loading}
                      name="fetch"
                      value={props.rows.fetch + props.rows.next}
                      onClick={(event) => props.handleRows(event)}
                      className={`-ml-px relative inline-flex items-center px-2 py-2 rounded-r-sm disabled:opacity-50 ${
                        total - props.rows.fetch <= props.rows.next &&
                        "pointer-events-none"
                      } border border-gray-400 bg-white text-sm leading-5 font-medium focus:outline-none hover:text-white hover:bg-sap-yellow hover:border-sap-yellow text-sap-blue transition ease-in-out duration-150`}
                      aria-label="Next"
                    >
                      <svg
                        className="h-5 w-5"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </button>
                    {/* Last */}
                    <button
                      disabled={total - props.rows.fetch <= props.rows.next || loading}
                      name="fetch"
                      value={total - props.rows.next}
                      onClick={(event) => props.handleRows(event)}
                      className={`-ml-px relative inline-flex items-center px-2 py-2 rounded-r-sm disabled:opacity-50 ${
                        total - props.rows.fetch <= props.rows.next && 
                        "pointer-event-none"
                    } border border-gray-400 bg-white text-sm leading-5 font-medium focus:outline-none hover:text-white hover:bg-sap-yellow hover:border-sap-yellow text-sap-blue transition ease-in-out duration-150`}
                    aria-label="Last"
                    >
                      <span>Fin</span>
                    </button>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="xl:hidden">
          {!list.length && loading && (
            <div className="xl:hidden lds-facebook mt-36">
              <div></div>
              <div></div>
              <div></div>
            </div>
          )}
        </div>
        <div className={`xl:hidden grid md:grid-cols-2 lg:grid-cols-3 transition ease-${
          list.length ? "out" : "in"
        } duration-${
          list.length ? "100" : "75"
        } transform opacity-${
          list.length ? "100" : "0"
        } scale-${list.length ? "100" : "95"}`}>
        {list.map((e) => (
          <div
            key={list.indexOf(e)} 
            className="p-1"
            onClick={() =>
              dispatch(
                getMissionOrders({ ...params, clgCode: e.clgCode })
              )
            }>
              <div className="border border-gray-300 bg-white p-4 rounded-sm">
                <span
                  className={`float-right relative inline-block px-3 py-1 font-semibold leading-tight`}>
                  <span
                    aria-hidden
                    className="absolute inset-0 bg-blue-200 opacity-50 rounded-full"
                  ></span>
                  <span className="relative">
                    {e.status}
                  </span>
                </span>
                <h2 className="text-lg font-medium title-font mb-2">
                  {e.clgCode}
                </h2>
                <hr />
                <div className="mt-2 leading-none flex justify-between w-full">
                  <span className="mr-3 inline-flex items-left leading-none text-sm  py-1">
                    {e.cardName}
                  </span>
                  <span className="inline-flex items-center leading-none text-sm">
                    <svg
                      className="min-w-5 min-h-5 w-4 h-4 mr-1 text-blue-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    {e.attendUser}
                  </span>
                </div>
                <p className="leading-relaxed text-base pt-2">
                  {e.details}
                </p>
                <hr />
                <div className="text-center mt-2 leading-none flex justify-between w-full">
                  <span className="mr-3 inline-flex items-center leading-none text-sm  py-1 ">
                    <svg
                      className="min-w-5 min-h-5 w-4 h-4 mr-1 text-blue-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>{" "}
                    {e.beginDate
                      .split(" ")[0]
                      .split("-")
                      .reverse()
                      .join("-")}
                  </span>
                  <span className="inline-flex items-center leading-none text-sm">
                    <svg
                      className="min-w-5 min-h-5 mr-1"
                      width="22"
                      height="22"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g fill="none" fillRule="evenodd">
                        <path
                          fill={
                            e.priority === "H" ? "#1F2937" : "#D8D8D8"
                          }
                          d="M15.6 17.667h3.5V3h-3.5z"
                        />
                        <path
                          fill={
                            e.priority !== "L" ? "#1F2937" : "#D8D8D8"
                          }
                          d="M9.2 6.583v11.08h3.5V6.583z"
                        />
                        <path
                          fill="#1F2937"
                          d="M2.6 17.667h3.5v-7.334H2.6z"
                        />
                      </g>
                    </svg>
                    {e.priority}
                  </span>
                </div>
              </div>
          </div>
        ))}
      </div>
    </>
      
    )
  );
};

export default List;
