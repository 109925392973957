import API from "../utils/API";
import { message } from "./message";

export const getMissionOrders = (params) => {
  return (dispatch) => {
    dispatch({
      type: "GET_MISSION_ORDERS",
      params: params,
      details: params.clgCode ? true : false,
    });
    API.missionOrders(params)
      .then((res) => {
        dispatch({
          type: "GET_MISSION_ORDERS_SUCCESS",
          payload: res,
        });
      })
      .catch((err) => {
        dispatch({ type: "GET_MISSION_ORDERS_FAILURE", payload: err });
        throw err;
      });
  };
};

export const setComment = (comment) => {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_MISSION_ORDERS_COMMENT",
      comment: comment,
    });
  };
};

export const setValidation = (state) => {
  return (dispatch) => {
    dispatch({
      type: "UPDATE_MISSION_ORDERS_STATE",
      state: state,
    });
  };
};

export const validateMissionOrder = (params) => {
  return (dispatch) => {
    dispatch({
      type: "VALIDATE_MISSION_ORDER",
    });
    API.validateMissionOrder(params)
      .then((res) => {
        dispatch({
          type: "VALIDATE_MISSION_ORDER_SUCCESS",
          payload: res,
        });
        dispatch(
          message({
            type: "success",
            title: "Succès",
            text: !params.valid
              ? `Commentaire ajouté sur l'OM n°${params.clgCode}`
              : `L'OM n°${params.clgCode} a été validé`,
          })
        );
        dispatch(getMissionOrders({ ...params, clgCode: "" }));
      })
      .catch((err) => {
        dispatch({ type: "VALIDATE_MISSION_ORDER_FAILURE", payload: err });
        dispatch(
          message({
            type: "error",
            title: `Erreur: ${err}`,
            text: `L'OM n°${params.clgCode} n'a pas été validé`,
          })
        );
        throw err;
      });
  };
};

export const toggleFilters = () => {
  return (dispatch) => {
    dispatch({ type: "TOGGLE_FILTERS" });
  };
};
