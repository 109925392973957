import React, { useState, useEffect, useCallback } from "react";
import API from "../../utils/API";
import User from "../User";
import { useHistory } from "react-router-dom";
import MessageOld from "../MessageOld";
import logo from "../../assets/logo_o1_sap.png";

const Team = () => {
  const [usersList, setUsersList] = useState([]),
    [show, setShow] = useState({
      add: false,
      update: false,
    }),
    [message, setMessage] = useState({}),
    history = useHistory(),
    getData = useCallback(() => {
      try {
        API.list("support")
          .then((res) => {
            setUsersList(res.data.list);
          })
          .catch((err) => {
            (err.response.status >= 400 || err.response.status === 500) &&
              setMessage({
                type: "error",
                title: "Erreur",
                text: err.response.data.text,
              });
            //history.push("/");
            //API.logout();
            //throw err;
          });
      } catch (error) {
        console.error(error);
      }
    }, [history]),
    closeUser = () => {
      setShow({
        add: false,
        update: false,
      });
      getData();
    };
  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <>
      <MessageOld message={message} cleanMsg={setMessage} />
      <header className="bg-white shadow mt-16">
        <div className="flex container mx-auto py-6 px-4 sm:px-6 lg:px-8">
          <h1 className="flex-1 text-3xl font-bold leading-tight text-sap-blue tracking-widest uppercase">
            Équipe
          </h1>
          <button
            className="flex-2 mr-3 bg-white focus:outline-none hover:text-white hover:bg-sap-yellow hover:border-sap-yellow text-sap-blue font-semibold py-2 px-2 border border-gray-400 rounded-full shadow transition ease-in-out duration-150"
            onClick={() =>
              setShow(
                show.update
                  ? { ...show, update: false }
                  : { ...show, add: !show.add }
              )
            }
          >
            {show.add || show.update ? (
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                />
              </svg>
            )}
          </button>
          <img
            className="h-10 flex-3 hidden md:block"
            src={logo}
            alt="compagny_logo"
          />
        </div>
      </header>
      <div className="flex-1 bg-gradient-to-b from-sap-blue via-sap-blue-light to-sap-gray p-5">
        <main className="antialiased font-sans mx-auto sm:w-full md:w-4/6">
          <div className="container mx-auto px-4 sm:px-8">
            <div className="py-5">
              <User
                show={show.add ? show.add : show.update}
                title={
                  show.add
                    ? "Ajouter un utilisateur à l'équipe"
                    : "Modifier cet utilisateur de l'équipe"
                }
                group="support"
                closeUpdateUser={closeUser}
                user={show.update && show.user}
              />
              <div
                className={`flex flex-col transition ease-${
                  !show.add && !show.update ? "out" : "in"
                } duration-${
                  !show.add && !show.update ? "100" : "75"
                } transform opacity-${
                  !show.add && !show.update ? "100" : "0"
                } scale-${!show.add && !show.update ? "100" : "95"} ${
                  (show.add || show.update) && "-z-1"
                } ${(show.add || show.update) && "absolute"}`}
              >
                <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-sm">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-white">
                          <tr>
                            <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider">
                              Nom
                            </th>
                            <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider">
                              Poste
                            </th>
                            <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider">
                              Statut
                            </th>
                            <th className="px-6 py-3 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-800 uppercase tracking-wider">
                              Rôle
                            </th>
                            <th className="px-6 py-3 bg-gray-50"></th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {usersList.map((user) => (
                            <tr key={user._id}>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="flex items-center">
                                  <div className="flex-shrink-0 h-10 w-10">
                                    <svg
                                      className="h-10 w-10 text-sap-blue"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                      stroke="currentColor"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth={2}
                                        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                      />
                                    </svg>
                                  </div>
                                  <div className="ml-4">
                                    <div className="text-sm leading-5 font-medium text-gray-900">
                                      {user.firstName} {user.lastName}
                                    </div>
                                    <div className="text-sm leading-5 text-gray-500">
                                      {user.email}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <div className="text-sm leading-5 text-gray-900">
                                  {user.company}
                                </div>
                                <div className="text-sm leading-5 text-gray-500">
                                  {user.position}
                                </div>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap">
                                <span
                                  className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                    user.active
                                      ? "bg-green-100 text-green-800"
                                      : "bg-red-100 text-red-800"
                                  }`}
                                >
                                  {user.active ? "Active" : "Disabled"}
                                </span>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm leading-5 text-gray-500">
                                {user.superuser ? "Admin" : "Support"}
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-right text-sm leading-5 font-medium">
                                <button
                                  className="flex-2 bg-white focus:outline-none hover:text-white hover:bg-sap-blue hover:border-sap-blue text-sap-blue font-semibold py-2 px-2 border border-gray-400 rounded-full shadow transition ease-in-out duration-150"
                                  onClick={() =>
                                    setShow({
                                      ...show,
                                      update: !show.update,
                                      user: user,
                                    })
                                  }
                                >
                                  <svg
                                    className="h-6 w-6"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                    stroke="currentColor"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      strokeWidth={2}
                                      d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                                    />
                                  </svg>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Team;
