import React, { useState, useEffect, useCallback } from "react";
import API from "../../utils/API";
import { useHistory } from "react-router-dom";
import MessageOld from "../MessageOld";

const User = (props) => {
  const [inputs, setInputs] = useState({
      _id: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      company: "",
      code: "",
      position: "",
      group: props.group,
      superuser: false,
      active: false,
    }),
    [data, setData] = useState({}),
    [message, setMessage] = useState({}),
    [submitted, setSubmitted] = useState(false),
    [modal, setmodal] = useState(false),
    {
      firstName,
      lastName,
      email,
      password,
      company,
      position,
      superuser,
      active,
    } = inputs,
    handleChange = (e) => {
      const { name, value } = e.target;
      setInputs((inputs) => ({ ...inputs, [name]: value }));
    },
    toggleState = (e) => {
      const { name } = e.target;
      setInputs((inputs) => ({ ...inputs, [name]: !inputs[name] }));
    },
    handleSubmit = async (e) => {
      e.preventDefault();
      setSubmitted(true);
      if (props.group === "clients") {
        const validCustomer = data.BusinessPartners.find((e) => {
          return e.cardName.toLowerCase() === inputs.company.toLowerCase();
        });
        if (!validCustomer) {
          return (
            setMessage({
              type: "error",
              title: "Erreur",
              text: "Société invalide",
            }),
            setInputs((inputs) => ({ ...inputs, company: "" })),
            setSubmitted(false)
          );
        }
        inputs.code = validCustomer.cardCode;
      } else {
        inputs.code = "O1";
      }
      if (!props.user) {
        delete inputs._id;
      }
      try {
        API[props.user ? "updateUser" : "addUser"](inputs)
          .then((response) => {
            (response.status === 200 || response.status === 201) &&
              setMessage({
                type: "success",
                title: "Succès",
                text: response.data.text,
              });
            response.status === 204 &&
              setMessage({
                type: "info",
                title: "Info",
                text: "Rien à modifier",
              });
            props.closeUpdateUser();
          })
          .catch((err) => {
            (err.response.status >= 400 || err.response.status === 500) &&
              setMessage({
                type: "error",
                title: "Erreur",
                text: err.response.data.text,
              });
            throw err;
          });
      } catch (error) {
        console.error(error);
      }
      setSubmitted(false);
    },
    handleDelete = async (e) => {
      e.preventDefault();
      setSubmitted(true);
      try {
        API.deleteUser({ _id: inputs._id })
          .then((response) => {
            response.status === 200 &&
              setMessage({
                type: "success",
                title: "Succès",
                text: response.data.text,
              });
            props.closeUpdateUser();
          })
          .catch((err) => {
            (err.response.status >= 400 || err.response.status === 500) &&
              setMessage({
                type: "error",
                title: "Erreur",
                text: err.response.data.text,
              });
            throw err;
          });
        setmodal(false);
      } catch (error) {
        console.error(error);
      }
      setSubmitted(false);
    },
    history = useHistory(),
    getData = useCallback(() => {
      try {
        API.partnersList().then((res) => {
          setData(res.data);
        });
        // .catch((err) => {
        //   (err.response.status >= 400 || err.response.status === 500) &&
        //     setMessage({
        //       type: "error",
        //       title: "Erreur",
        //       text: err.response.data.text,
        //     });
        //   //history.push("/");
        //   //API.logout();
        //   //throw err;
        // });
      } catch (error) {
        console.error(error);
      }
    }, [history]);

  useEffect(() => {
    if (props.group === "clients") {
      getData();
    }
  }, [getData, props.group]);

  useEffect(() => {
    setInputs({
      _id: props.user?._id || "",
      firstName: props.user?.firstName || "",
      lastName: props.user?.lastName || "",
      email: props.user?.email || "",
      password: "",
      company: props.user?.company || "",
      position: props.user?.position || "",
      group: props.group,
      superuser: props.user?.superuser || false,
      active: props.user?.active || false,
    });
  }, [props.user, props.group]);

  return (
    <>
      <MessageOld message={message} cleanMsg={setMessage} />
      <div
        className={`container absolute right-0 left-0 mx-auto transition ease-${
          props.show ? "out" : "in"
        } duration-${props.show ? "100" : "75"} transform opacity-${
          props.show ? "100" : "0"
        } scale-${props.show ? "100" : "95"}  ${!props.show && "-z-1 top-0"}`}
      >
        <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-sm mx-auto sm:w-full md:w-4/6">
          <main className="bg-white shadow mx-auto py-6 px-4 sm:px-6 lg:px-8">
            <h1 className="text-2xl pb-6 text-center font-bold leading-tight text-sap-blue">
              {props.title}
            </h1>
            <hr className="mx-auto py-4 px-4" />
            <form
              autoComplete="off"
              className="w-full max-w-lg content-center mx-auto"
              onSubmit={handleSubmit}
            >
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                  <div className="flex items-center border-b border-gray-800 py-2">
                    <input
                      className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                      type="text"
                      name="firstName"
                      placeholder="Prénom"
                      aria-label="Full name"
                      required
                      value={firstName}
                      onChange={(event) => handleChange(event)}
                      disabled={submitted}
                    />
                  </div>
                  {firstName && (
                    <p className="absolute text-gray-600 text-xs">Prénom</p>
                  )}
                </div>
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                  <div className="flex items-center border-b border-gray-800 py-2">
                    <input
                      className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                      type="text"
                      name="lastName"
                      placeholder="Nom"
                      aria-label="Full name"
                      required
                      value={lastName}
                      onChange={(event) => handleChange(event)}
                      disabled={submitted}
                    />
                  </div>
                  {lastName && (
                    <p className="absolute text-gray-600 text-xs">Nom</p>
                  )}
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <div className="flex items-center border-b border-gray-800 py-2">
                    <input
                      className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                      type="text"
                      name="email"
                      placeholder="Email"
                      aria-label="Full name"
                      required
                      value={email}
                      onChange={(event) => handleChange(event)}
                      disabled={submitted}
                    />
                  </div>
                  {email && (
                    <p className="absolute text-gray-600 text-xs">Email</p>
                  )}
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full px-3">
                  <div className="flex items-center border-b border-gray-800 py-2">
                    <input
                      className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                      type="password"
                      name="password"
                      placeholder={`${
                        props.user ? "Changer de mot de passe" : "Mot de passe"
                      }`}
                      aria-label="Full name"
                      value={password}
                      onChange={(event) => handleChange(event)}
                      disabled={submitted}
                    />
                  </div>
                  {password && (
                    <p className="absolute text-gray-600 text-xs">
                      Mot de passe
                    </p>
                  )}
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mb-6">
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                  <div className="flex items-center border-b border-gray-800 py-2">
                    <input
                      className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                      type="text"
                      name="company"
                      placeholder="Société"
                      aria-label="Full name"
                      required
                      value={company}
                      onChange={(event) => handleChange(event)}
                      disabled={submitted}
                      list="partnersList"
                    />
                    <datalist id="partnersList">
                      {data.BusinessPartners?.map((item, key) => (
                        <option key={key} value={item.cardName} />
                      ))}
                    </datalist>
                  </div>
                  {company && (
                    <p className="absolute text-gray-600 text-xs">Société</p>
                  )}
                </div>
                <div className="w-full md:w-1/2 px-3 md:mb-0">
                  <div className="flex items-center border-b border-gray-800 py-2">
                    <input
                      className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                      type="text"
                      name="position"
                      placeholder="Poste"
                      aria-label="Full name"
                      required
                      value={position}
                      onChange={(event) => handleChange(event)}
                      disabled={submitted}
                    />
                  </div>
                  {position && (
                    <p className="absolute text-gray-600 text-xs">Poste</p>
                  )}
                </div>
              </div>
              <div
                className={`grid grid-cols-${
                  props.group === "support" && props.user
                    ? "4"
                    : props.group === "support" || props.user
                    ? "3"
                    : "2"
                } pt-4`}
              >
                {props.user && (
                  <div className="col-1">
                    <button
                      type="button"
                      className="flex-2 bg-white text-red-800 focus:outline-none hover:text-white hover:bg-red-800 font-semibold py-2 px-2 border border-red-400 rounded-full shadow transition ease-in-out duration-150"
                      onClick={() => setmodal(true)}
                    >
                      <svg
                        className="h-6 w-6"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        />
                      </svg>
                    </button>
                  </div>
                )}
                {props.group === "support" && (
                  <div className="col-1">
                    <label className="flex justify-start items-start pt-2">
                      <div className="bg-white border-2 rounded border-gray-400 w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2 focus-outline-none">
                        <input
                          type="checkbox"
                          name="superuser"
                          className="opacity-0 absolute"
                          onClick={(event) => toggleState(event)}
                        />
                        {superuser && (
                          <svg
                            className="fill-current w-4 h-4 text-sap-blue pointer-events-none"
                            viewBox="0 0 20 20"
                          >
                            <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                          </svg>
                        )}
                      </div>
                      <div className="select-none ml-2 text-gray-600">
                        Administrateur
                      </div>
                    </label>
                  </div>
                )}
                <div className="col-2">
                  <label
                    className={`float-${
                      props.group === "support" ? "right" : "left"
                    } flex justify-start items-start pt-2`}
                  >
                    <div className="bg-white border-2 rounded border-gray-400 w-6 h-6 flex flex-shrink-0 justify-center items-center mr-2 focus-outline-none">
                      <input
                        type="checkbox"
                        name="active"
                        className="opacity-0 absolute"
                        onClick={(event) => toggleState(event)}
                      />
                      {active && (
                        <svg
                          className="fill-current w-4 h-4 text-sap-blue pointer-events-none"
                          viewBox="0 0 20 20"
                        >
                          <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                        </svg>
                      )}
                    </div>
                    <div className="select-none ml-2 text-gray-600">
                      Activer
                    </div>
                  </label>
                </div>
                <div className="col-3">
                  <button
                    className="float-right items-end flex-shrink-0 bg-white focus:outline-none hover:text-white hover:bg-sap-blue hover:border-sap-blue text-sap-blue font-semibold py-2 px-2 border border-gray-400 rounded-full shadow transition ease-in-out duration-150"
                    type="submit"
                  >
                    <svg
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M5 13l4 4L19 7"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </form>
          </main>
        </div>
      </div>

      <div
        className={`fixed ${modal ? "z-10" : "-z-1"} inset-0 overflow-y-auto`}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div
            className={`fixed inset-0 transition ease-${
              !modal ? "out" : "in"
            } duration-${!modal ? "300" : "200"} transform opacity-${
              !modal ? "0" : "100"
            }`}
          >
            <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen"></span>
          &#8203;
          <div
            className={`inline-block align-bottom bg-white rounded-sm text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full transition ease-${
              !modal ? "out" : "in"
            } duration-${!modal ? "300" : "200"} transform opacity-${
              !modal ? "0" : "100"
            } translate-y-${!modal ? "4" : "0"} sm:scale-${
              !modal ? "95" : "100"
            } ${!modal && "sm:translate-y-0"}`}
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
          >
            <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <svg
                    className="h-6 w-6 text-red-600"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
                    />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3
                    className="text-lg leading-6 font-medium text-gray-900"
                    id="modal-headline"
                  >
                    Supprimer cet utilisateur ?
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm leading-5 text-gray-500">
                      Cette action est définitive, êtes-vous sûre de vouloir
                      supprimer <b>{firstName}</b> <b>{lastName}</b> ?
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <span className="flex w-full rounded-sm shadow-sm sm:ml-3 sm:w-auto">
                <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-sm border border-transparent px-4 py-2 bg-red-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-red-500 focus:outline-none focus:border-red-700 focus:ring-red transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                  onClick={handleDelete}
                >
                  Supprimer
                </button>
              </span>
              <span className="mt-3 flex w-full rounded-sm shadow-sm sm:mt-0 sm:w-auto">
                <button
                  type="button"
                  className="inline-flex justify-center w-full rounded-sm border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:ring-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
                  onClick={() => setmodal(false)}
                >
                  Annuler
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default User;
