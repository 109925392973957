export default (
  state = {
    data: [],
    total: 0,
    details: false,
    valid: false,
    comment: { state: false, text: "" },
    filters: { isOpen: false },
  },
  action
) => {
  const payload = action.payload;
  switch (action.type) {
    case "GET_MISSION_ORDERS":
      return {
        ...state,
        data: [],
        loading: true,
        loaded: false,
        params: action.params,
        details: action.details,
      };
    case "GET_MISSION_ORDERS_SUCCESS":
      return {
        ...state,
        data: payload.data.MissionOrders,
        total: payload.data.Total,
        loading: false,
        loaded: true,
        error: null,
      };
    case "GET_MISSION_ORDERS_FAILURE":
      return {
        ...state,
        loading: false,
        loaded: true,
        error: payload,
      };
    case "UPDATE_MISSION_ORDERS_COMMENT":
      return {
        ...state,
        comment: action.comment,
      };
    case "UPDATE_MISSION_ORDERS_STATE":
      return {
        ...state,
        valid: action.state,
      };
    case "VALIDATE_MISSION_ORDER":
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    case "VALIDATE_MISSION_ORDER_SUCCESS":
      return {
        ...state,
        loading: false,
        loaded: true,
        error: null,
        params: {},
        details: false,
        valid: false,
      };
    case "VALIDATE_MISSION_ORDER_FAILURE":
      return {
        ...state,
        loading: false,
        loaded: true,
        error: payload,
        details: true,
        valid: false,
      };
    case "TOGGLE_FILTERS":
      return {
        ...state,
        filters: { ...state.filters, isOpen: !state.filters.isOpen },
      };
    default:
      return state;
  }
};
