import { combineReducers } from "redux";
import message from "./message.js";
import missionOrders from "./missionOrders.js";

const rootReducer = combineReducers({
  message,
  missionOrders,
});

export default rootReducer;
