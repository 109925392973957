import React from "react";
import { BrowserRouter } from "react-router-dom";
import Router from "./router";
import ScrollTop from "./components/ScrollTop";
import './style/style.scss';

const App = () => (
  <BrowserRouter>
    <div className="flex flex-col h-screen">
      <Router />
      <ScrollTop />
    </div>
  </BrowserRouter>
);

export default App;
