import React, { useState, useEffect } from "react";
import API from "../../utils/API";

const Footer = () => {
  const [version, setVersion] = useState(false),
    getData = () => {
      try {
        API.version().then((res) => {
          setVersion(res.data.version);
        });
      } catch (error) {
        console.error(error);
      }
    };
  useEffect(() => {
    getData();
  }, []);

  return (
    <footer>
      <div className="text-center pb-1 pt-5 text-sap-blue">
        Contactez-nous | Tel : 02 37 84 16 00 | <a href="mailto:contact@opti-one.fr">contact@opti-one.fr</a>
      </div>
      <div className="text-center pt-1 pb-5 text-sap-blue">
        © 2020 Copyright:⠀
        <a href="https://www.opti-one.fr/">Opti-One</a> - {version}
      </div>
    </footer>
  );
};

export default Footer;
