import React from "react";
import API from "../utils/API.js";
import { Route, Switch, Redirect } from "react-router-dom";
import Message from "../components/Message";
import * as pages from "./pages";
import { PrivateRoute } from "./private_route";

const Router = () => (
  <>
    <Message />
    <Switch>
      <Route exact path="/login" component={pages.Login} />
      <PrivateRoute exact path="/dashboard" component={pages.Dashboard} />
      <PrivateRoute exact path="/team" component={pages.Team} />
      <PrivateRoute exact path="/clients" component={pages.Clients} />
      <PrivateRoute exact path="/calendar" component={pages.Calendar} />
      <PrivateRoute
        exact
        path="/missionsorders"
        component={pages.MissionsOrders}
      />
      {API.isAuth() ? (
        <Redirect from="/" to="/dashboard" />
      ) : (
        <Redirect from="/" to="/login" />
      )}
    </Switch>
  </>
);

export default Router;
