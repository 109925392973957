import axios from "axios";

const getCookie = (cname) => {
  const name = cname + "=",
    decodedCookie = decodeURIComponent(document.cookie),
    ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

axios.defaults.withCredentials = true;
axios.defaults.headers.post["Content-Type"] = "application/json";

export default {
  login: (params) => axios.post("/user/login", params),
  isAuth: () => (getCookie("access") !== "" ? true : false),
  logout: () =>
    (document.cookie =
      "access=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;"),
  version: () => axios.get("/version"),
  addUser: (params) => axios.post("/user/addUser", params),
  updateUser: (params) => axios.post("/user/updateUser", params),
  deleteUser: (params) => axios.post("/user/deleteUser", params),
  list: (group) => axios.get(`/user/list/${group}`),
  menu: () => axios.get("/user/menu"),
  partnersList: () => axios.get("/user/partners-list"),
  serviceCalls: (params) => axios.post("/service-call/list", params),
  probCallTypes: () => axios.get("/service-call/prob_call_types"),
  saveServiceCall: (params) => axios.post("/service-call/save", params),
  updateServiceCall: (params) => axios.post("/service-call/update", params),
  missionOrders: (params) => axios.post("/mission-orders/activities", params),
  validateMissionOrder: (params) => axios.post("/mission-orders/validate", params),
};
