import React, { useState, useEffect, useCallback } from "react";
import API from "../../utils/API";
import { useHistory } from "react-router-dom";
import MessageOld from "../MessageOld";

const Filters = (props) => {
  const [isOpen, setPanel] = useState(false),
    [partnersList, setPartnersList] = useState(undefined),
    [message, setMessage] = useState({}),
    [dropdown, toggleDropdown] = useState({
      search: false,
      clients: false,
      rows: false,
      status: false,
      priority: false,
      importance: false,
    }),
    closeAll = () => {
      toggleDropdown({
        search: false,
        clients: false,
        rows: false,
        status: false,
        priority: false,
        importance: false,
      });
    },
    closePanel = () => {
      setPanel(false);
      props.setDashboardWidth(false);
    },
    hide = (e) => {
      if (e && e.relatedTarget) {
        e.relatedTarget.click();
      }
      closeAll();
    },
    checkFilters = () => {
      if (
        props.rows !== 20 ||
        props.status !== "Tous" ||
        props.client !== "Tous" ||
        props.priority !== "Toutes" ||
        props.importance !== "Toutes" ||
        props.typing
      ) {
        return true;
      } else {
        return false;
      }
    },
    clearFilters = () => {
      props.clearFilters();
      closeAll();
    },
    history = useHistory(),
    getData = useCallback(() => {
      try {
        API.partnersList()
          .then((res) => {
            console.log("We got PartnerList.");
            res.data.BusinessPartners &&
              setPartnersList([
                { cardCode: "", cardName: "Tous" },
                ...res.data.BusinessPartners,
              ]);
          })
          .catch((err) => {
            // (err.response.status >= 400 || err.response.status === 500) &&
            //   setMessage({
            //     type: "error",
            //     title: "Erreur",
            //     text: err.response.data.text,
            //   });
            //history.push("/");
            //API.logout();
            //throw err;
          });
      } catch (error) {
        console.error(error);
      }
    }, [history]);

  useEffect(() => {
    if (props.isFiltersPanelOpen !== isOpen) {
      setPanel(props.isFiltersPanelOpen);
    }
  }, [props.isFiltersPanelOpen, isOpen]);
  useEffect(() => {
    getData();
  }, [getData]);
  return (
    <>
      <MessageOld message={message} cleanMsg={setMessage} />
      <div
        className={`fixed inset-0 overflow-hidden z-40 lg:w-1/4 transition duration-${
          isOpen ? "700" : "700"
        } transform-gpu ${!isOpen ? "-translate-x-full" : "translate-x-0"}`}
      >
        <div className="absolute inset-0 overflow-hidden">
          <section
            className="absolute inset-y-0 left-0 pr-10 max-w-full flex"
            aria-labelledby="slide-over-heading"
          >
            <div
              className={`relative w-screen max-w-sm transition ease-${
                isOpen ? "in" : "out"
              } duration-${isOpen ? "500" : "500"} sm:duration-${
                isOpen ? "700" : "700"
              } transform-gpu ${
                !isOpen ? "-translate-x-full" : "translate-x-0"
              }`}
            >
              <div
                className={`absolute top-0 right-0 mt-20 mr-6 pt-5 transition ease-${
                  isOpen ? "in" : "out"
                } duration-${isOpen ? "500" : "500"} opacity-${
                  isOpen ? "100" : "0"
                }`}
              >
                <button
                  className="rounded-md text-sap-blue hover:text-sap-yellow focus:outline-none focus:ring-2 focus:ring-white"
                  onClick={closePanel}
                >
                  <span className="sr-only">Close panel</span>
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-hidden">
                <div className="absolute left-8 top-4">
                  <h2 className="text-2xl text-sap-blue">O1 - SUPPORT</h2>
                </div>
                <div className="md:flex flex-col md:flex-row md:min-h-screen w-full">
                  <div className="flex flex-col w-full text-gray-700 bg-white  flex-shrink-0">
                    <div className="flex-shrink-0 px-8 py-4 flex flex-row items-center justify-between">
                      <p className="mt-14 text-lg font-semibold tracking-widest text-sap-blue uppercase rounded-lg focus:outline-none focus:shadow-outline">
                        Filtres de recherche
                      </p>
                    </div>
                    <nav className="flex-grow md:block px-4 pb-4 md:pb-0 md:overflow-y-auto">
                      {!dropdown.search && !props.typing && (
                        <button
                          className="flex-row items-center w-full px-4 py-2 mt-2 text-sm font-semibold text-left bg-transparent rounded-sm md:block text-sap-blue hover:bg-gray-200 hover:text-sap-yellow focus:outline-none focus:shadow-outline"
                          onClick={() => {
                            toggleDropdown({
                              ...dropdown,
                              search: !dropdown.search,
                            });
                          }}
                        >
                          <span>Recherche</span>
                          <span className="float-right">
                            <svg
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              className="inline w-4 h-4 mt-1 ml-1 transition-transform duration-200 transform -mt-1"
                            >
                              <path
                                fillRule="evenodd"
                                d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                          </span>
                        </button>
                      )}
                      {(dropdown.search || props.typing) && (
                        <div className="relative">
                          <span className="h-full absolute inset-y-0 left-0 flex items-center pl-2">
                            <svg
                              viewBox="0 0 24 24"
                              className="h-4 w-4 fill-current text-gray-500"
                            >
                              <path d="M10 4a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1114.32 4.906l5.387 5.387a1 1 0 01-1.414 1.414l-5.387-5.387A8 8 0 012 10z"></path>
                            </svg>
                          </span>
                          <input
                            onChange={(event) =>
                              props.setTyping(event.target.value)
                            }
                            type="search"
                            autoFocus
                            value={props.typing}
                            onBlur={(e) => hide(e)}
                            placeholder="Recherche"
                            className="flex flex-row items-center w-full pl-8 pr-4 py-2 mt-2 text-sm font-semibold text-left bg-transparent rounded-sm md:block hover:bg-gray-200 focus:outline-none focus:shadow-outline text-sap-blue"
                          />
                        </div>
                      )}
                      <div className="relative">
                        <button
                          className={`flex-row items-center w-full px-4 py-2 mt-2 text-sm font-semibold text-left bg-transparent rounded-sm md:block hover:bg-gray-200 hover:text-sap-yellow focus:outline-none focus:shadow-outline ${
                            props.status !== "Tous"
                              ? "text-sap-yellow"
                              : "text-sap-blue"
                          }`}
                          onClick={() => {
                            toggleDropdown({
                              ...dropdown,
                              status: !dropdown.status,
                            });
                          }}
                          onBlur={(e) => hide(e)}
                        >
                          <span>Statut</span>
                          <svg
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            className={`inline w-4 h-4 mt-1 ml-1 transition-transform duration-200 transform -mt-1 transform rotate-${
                              dropdown.status ? "180" : "0"
                            }`}
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          <span className="float-right">{props.status}</span>
                        </button>
                        <div
                          className={`absolute right-0 w-full mt-2 origin-top-right rounded-sm shadow-lg transition ease-${
                            dropdown.status ? "out" : "in"
                          } duration-${
                            dropdown.status ? "100" : "75"
                          } transform opacity-${
                            dropdown.status ? "100" : "0"
                          } scale-${dropdown.status ? "100" : "95"} ${
                            dropdown.status ? "z-10" : "-z-1"
                          }`}
                        >
                          <div className="px-2 py-2 bg-white rounded-sm shadow ">
                            {["Tous", "Ouverts", "Clôturés"].map((e) => (
                              <button
                                key={e}
                                className="block w-full text-left px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-sm md:mt-0 text-sap-blue hover:bg-gray-200 hover:text-sap-yellow focus:outline-none focus:shadow-outline"
                                value={e}
                                onClick={(event) => props.handleStatus(event)}
                              >
                                {e}
                              </button>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="relative">
                        <button
                          className={`flex-row items-center w-full px-4 py-2 mt-2 text-sm font-semibold text-left bg-transparent rounded-sm md:block hover:bg-gray-200 hover:text-sap-yellow focus:outline-none focus:shadow-outline ${
                            props.rows !== 20
                              ? "text-sap-yellow"
                              : "text-sap-blue"
                          }`}
                          onClick={() => {
                            toggleDropdown({
                              ...dropdown,
                              rows: !dropdown.rows,
                            });
                          }}
                          onBlur={(e) => hide(e)}
                        >
                          <span>Lignes</span>
                          <svg
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            className={`inline w-4 h-4 mt-1 ml-1 transition-transform duration-200 transform -mt-1 transform rotate-${
                              dropdown.rows ? "180" : "0"
                            }`}
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          <span className="float-right">{props.rows}</span>
                        </button>
                        <div
                          className={`absolute right-0 w-full mt-2 origin-top-right rounded-sm shadow-lg transition ease-${
                            dropdown.rows ? "out" : "in"
                          } duration-${
                            dropdown.rows ? "100" : "75"
                          } transform opacity-${
                            dropdown.rows ? "100" : "0"
                          } scale-${dropdown.rows ? "100" : "95"} ${
                            dropdown.rows ? "z-10" : "-z-1"
                          }`}
                        >
                          <div className="px-2 py-2 bg-white rounded-sm shadow ">
                            {[20, 30, 50].map((e) => (
                              <button
                                key={e}
                                className="block w-full text-left px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-sm md:mt-0 text-sap-blue hover:bg-gray-200 hover:text-sap-yellow hover:text-sap-yellow focus:outline-none focus:shadow-outline"
                                name="next"
                                value={e}
                                onClick={(event) => props.handleRows(event)}
                              >
                                {e}
                              </button>
                            ))}
                          </div>
                        </div>
                      </div>
                      {partnersList && (
                        <div className="relative">
                          <button
                            className={`flex-row items-center w-full px-4 py-2 mt-2 text-sm font-semibold text-left bg-transparent rounded-sm md:block hover:bg-gray-200 hover:text-sap-yellow focus:outline-none focus:shadow-outline ${
                              props.client !== "Tous"
                                ? "text-sap-yellow"
                                : "text-sap-blue"
                            }`}
                            onClick={() => {
                              toggleDropdown({
                                ...dropdown,
                                clients: !dropdown.clients,
                              });
                            }}
                            onBlur={(e) => hide(e)}
                          >
                            <span>Client</span>
                            <svg
                              fill="currentColor"
                              viewBox="0 0 20 20"
                              className={`inline w-4 h-4 mt-1 ml-1 transition-transform duration-200 transform -mt-1 transform rotate-${
                                dropdown.clients ? "180" : "0"
                              }`}
                            >
                              <path
                                fillRule="evenodd"
                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                clipRule="evenodd"
                              ></path>
                            </svg>
                            <span className="float-right truncate customMxWdth">
                              {props.client}
                            </span>
                          </button>
                          <div
                            className={`absolute right-0 w-full mt-2 origin-top-right rounded-sm shadow-lg transition ease-${
                              dropdown.clients ? "out" : "in"
                            } duration-${
                              dropdown.clients ? "100" : "75"
                            } transform opacity-${
                              dropdown.clients ? "100" : "0"
                            } scale-${dropdown.clients ? "100" : "95"} ${
                              dropdown.clients ? "z-10" : "-z-1"
                            }`}
                          >
                            <div className="px-2 py-2 bg-white rounded-sm shadow  max-h-96 overflow-hidden overflow-y-scroll">
                              {partnersList?.map((e) => (
                                <button
                                  key={e.cardCode}
                                  className="block w-full text-left px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-sm md:mt-0 text-sap-blue hover:bg-gray-200 hover:text-sap-yellow focus:outline-none focus:shadow-outline"
                                  value={e.cardName}
                                  onClick={() => props.handleClient(e)}
                                >
                                  {e.cardName}
                                </button>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="relative">
                        <button
                          className={`flex-row items-center w-full px-4 py-2 mt-2 text-sm font-semibold text-left bg-transparent rounded-sm md:block hover:bg-gray-200 hover:text-sap-yellow focus:outline-none focus:shadow-outline ${
                            props.priority !== "Toutes"
                              ? "text-sap-yellow"
                              : "text-sap-blue"
                          }`}
                          onClick={() => {
                            toggleDropdown({
                              ...dropdown,
                              priority: !dropdown.priority,
                            });
                          }}
                          onBlur={(e) => hide(e)}
                        >
                          <span>Priorité</span>
                          <svg
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            className={`inline w-4 h-4 mt-1 ml-1 transition-transform duration-200 transform -mt-1 transform rotate-${
                              dropdown.priority ? "180" : "0"
                            }`}
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          <span className="float-right">{props.priority}</span>
                        </button>
                        <div
                          className={`absolute right-0 w-full mt-2 origin-top-right rounded-sm shadow-lg transition ease-${
                            dropdown.priority ? "out" : "in"
                          } duration-${
                            dropdown.priority ? "100" : "75"
                          } transform opacity-${
                            dropdown.priority ? "100" : "0"
                          } scale-${dropdown.priority ? "100" : "95"} ${
                            dropdown.priority ? "z-10" : "-z-1"
                          }`}
                        >
                          <div className="px-2 py-2 bg-white rounded-sm shadow ">
                            {["Toutes", "Basse", "Moyenne", "Élevée"].map(
                              (e) => (
                                <button
                                  key={e}
                                  className="block w-full text-left px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-sm md:mt-0 text-sap-blue hover:bg-gray-200 hover:text-sap-yellow focus:outline-none focus:shadow-outline"
                                  value={e}
                                  onClick={(event) =>
                                    props.handlePriority(event)
                                  }
                                >
                                  {e}
                                </button>
                              )
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="relative">
                        <button
                          className={`flex-row items-center w-full px-4 py-2 mt-2 text-sm font-semibold text-left bg-transparent rounded-sm md:block hover:bg-gray-200 hover:text-sap-yellow focus:outline-none focus:shadow-outline ${
                            props.importance !== "Toutes"
                              ? "text-sap-yellow"
                              : "text-sap-blue"
                          }`}
                          onClick={() => {
                            toggleDropdown({
                              ...dropdown,
                              importance: !dropdown.importance,
                            });
                          }}
                          onBlur={(e) => hide(e)}
                        >
                          <span>Importance client</span>
                          <svg
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            className={`inline w-4 h-4 mt-1 ml-1 transition-transform duration-200 transform -mt-1 transform rotate-${
                              dropdown.importance ? "180" : "0"
                            }`}
                          >
                            <path
                              fillRule="evenodd"
                              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                          <span className="float-right">
                            {props.importance}
                          </span>
                        </button>
                        <div
                          className={`absolute right-0 w-full mt-2 origin-top-right rounded-sm shadow-lg transition ease-${
                            dropdown.importance ? "out" : "in"
                          } duration-${
                            dropdown.importance ? "100" : "75"
                          } transform opacity-${
                            dropdown.importance ? "100" : "0"
                          } scale-${dropdown.importance ? "100" : "95"} ${
                            dropdown.importance ? "z-10" : "-z-1"
                          }`}
                        >
                          <div className="px-2 py-2 bg-white rounded-sm shadow ">
                            {[
                              "Toutes",
                              "Annulé",
                              "Suspendu",
                              "Non urgent",
                              "Standard",
                              "Prioritaire",
                            ].map((e) => (
                              <button
                                key={e}
                                className={`block w-full text-left px-4 py-2 mt-2 text-sm font-semibold bg-transparent rounded-sm md:mt-0 text-sap-blue hover:bg-gray-200 hover:text-sap-yellow focus:outline-none focus:shadow-outline`}
                                value={e}
                                onClick={(event) =>
                                  props.handleImportance(event)
                                }
                              >
                                {e}
                              </button>
                            ))}
                          </div>
                        </div>
                      </div>
                      <button
                        className={`flex-row items-center w-full px-4 py-2 mt-2 text-sm font-semibold text-left bg-transparent rounded-sm md:block text-sap-blue hover:bg-gray-200 hover:text-sap-yellow focus:outline-none focus:shadow-outline transition ease-${
                          checkFilters() ? "out" : "in"
                        } duration-${
                          checkFilters() ? "100" : "75"
                        } transform opacity-${
                          checkFilters() ? "100" : "0"
                        } scale-${checkFilters() ? "100" : "95"}`}
                        onClick={() => {
                          clearFilters();
                        }}
                      >
                        <span>Réinitialiser</span>
                        <span className="float-right">
                          <svg
                            className="h-4 w-4"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth={2}
                              d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
                            ></path>
                          </svg>
                        </span>
                      </button>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Filters;
