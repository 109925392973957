import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getMissionOrders, validateMissionOrder } from "../../../actions";

const Actions = (props) => {
  const dispatch = useDispatch(),
    loading = useSelector((state) => state.missionOrders.loading),
    open = useSelector((state) => state.missionOrders.details),
    // params = useSelector((state) => state.missionOrders.params),
    [params, setParams] = useState({
      clgCode: props.client.cardName,
      fetch: 0,
      next: props.rows,
      rows: props.rows,
      status: props.status,
      closed: props.closed,
    }),
    valid = useSelector((state) => state.missionOrders.valid),
    comment = useSelector((state) => state.missionOrders.comment);
    
  return (
    open && (
      <>
        {/* Mettre a jour et fermer */}
        <h2 className="hidden md:block text-lg font-semibold tracking-widest text-sap-blue uppercase text-right md:mt-2 mr-4">
          {valid || comment.text ? "Mettre à jour et fermer" : "Fermer"}
        </h2>
        <button
          className="z-20 flex-2 mr-3 bg-white focus:outline-none hover:text-white hover:bg-sap-yellow hover:border-sap-yellow text-sap-blue font-semibold py-2 px-2 border border-gray-400 rounded-full shadow transition ease-in-out duration-150"
          onClick={
            valid || comment.text
              ? () =>
                  dispatch(validateMissionOrder({ ...params, comment, valid }))
              : () => {
                props.handleClient({ cardCode: "", cardName: "" });
              }
          }
          disabled={loading}
        >
          <svg
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d={valid || comment.text ? "M5 13l4 4L19 7" : "M6 18L18 6M6 6l12 12"}
            />
          </svg>
        </button>
      </>
    )
  );
};

export default Actions;
